import React from 'react';
import { shape, number, func, arrayOf, string } from 'prop-types';
import { Text, Flex } from '@chakra-ui/core';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import AssetManager from '../AssetManager';

function DatesAndCoverage({ onSubmit, policy, hideButtons }) {
  return (
    <FormBox heading="Policy dates and coverage">
      <FormControls
        initialValues={policy}
        onSubmit={onSubmit}
        hideButtons={hideButtons}
      >
        {({ editable }) => (
          <>
            <FormField
              label="Policy start date"
              editable={editable}
              fields={[
                {
                  name: 'start_date',
                  placeholder: 'Policy start date (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
              ]}
            />
            <FormField
              label="Policy expiration date"
              editable={editable}
              fields={[
                {
                  name: 'expiration_date',
                  placeholder: 'Policy expiration date (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
              ]}
            />
            <FormField
              label="Risk cover amount"
              editable={editable}
              fields={[
                {
                  name: 'risk_cover_amount',
                  type: 'monetary',
                  currency: 'EUR',
                  placeholder: 'Risk cover amount',
                  validations: {
                    min: 0,
                  },
                },
              ]}
            />
            <FormField
              label="Currency"
              editable={editable}
              fields={[
                {
                  name: 'risk_cover_currency',
                  placeholder: 'Default: EUR',
                },
              ]}
            />

            <Flex
              align="stretch"
              justify="flex-start"
              p="20px 20px 0"
              bg={editable ? 'sky' : 'white'}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Flex flexBasis="20%" align="stretch">
                <Text as="label" fontWeight="bold" marginBottom="20px">
                  For ULIP or VUL only
                </Text>
              </Flex>
            </Flex>

            <FormField
              label="Original investment"
              editable={editable}
              fields={[
                {
                  name: 'original_investment_amount',
                  type: 'monetary',
                  currency: 'EUR',
                  placeholder: 'Original investment amount',
                  validations: {
                    min: 0,
                  },
                },
              ]}
            />
            <FormField
              label="Most recent valuation"
              editable={editable}
              fields={[
                {
                  name: 'most_recent_value',
                  type: 'monetary',
                  currency: 'EUR',
                  placeholder: 'Value at date of last check',
                  validations: {
                    min: 0,
                  },
                },
              ]}
            />
            <FormField
              label="Last valuation date"
              editable={editable}
              fields={[
                {
                  name: 'last_valuation_date',
                  placeholder: 'Date of last check (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
              ]}
            />
            <AssetManager
              assetManagerData={policy && policy.asset_managers}
              editable={editable}
            />
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

DatesAndCoverage.propTypes = {
  onSubmit: func,
  policy: shape({
    id: number,
  }).isRequired,
  hideButtons: arrayOf(string),
};

DatesAndCoverage.defaultProps = {
  onSubmit: () => {},
  policy: {},
  hideButtons: [],
};

export default DatesAndCoverage;
