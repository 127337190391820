export const formatDate = ms => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const date = new Date(ms);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const weekDay = days[date.getDay()];
  return `${weekDay} ${month} ${day}`;
};

export const toggleBodyClass = (className, shouldRemove) => {
  document.body.className = (shouldRemove
    ? [document.body.className].filter(cls => cls !== className)
    : [document.body.className, className]
  )
    .filter(Boolean)
    .join(' ');
};
