import React from 'react';
import { string } from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ContactPersons from './ContactPersons';
import Credentials from './Credentials';
import DeleteClient from './DeleteClient';
import Loading from '../Loading';
import Error from '../Error';
import ClientHeader from '../ClientHeader';
import { useAuth0UsersByRole, useAuth0Roles } from '../../hooks/Auth0';

const PERSON_FIELDS = gql`
  fragment childFields on persons {
    id
    first_name
    last_name
    personal_id
    date_of_birth
    nationality
    withexspouse
  }

  fragment personFields on persons {
    id
    email
    auth0_sub
    first_name
    last_name
    personal_id
    date_of_birth
    nationality
    taxation_country
    street_address
    postal_code
    city
    phone_number
    marital_status
    spouse_id
    parent_id
    withexspouse
    accountable_id
    backup_person_id
    spouse {
      id
      spouse_id
      first_name
      last_name
      personal_id
      date_of_birth
      nationality
      taxation_country
      street_address
      postal_code
      city
      phone_number
      marital_status
      children {
        ...childFields
      }
    }
    children {
      parent_id
      ...childFields
    }
    grandchildren {
      grandparent_id
      ...childFields
    }
  }
`;

const GET_PERSON = gql`
  query getPerson($personId: Int!) {
    person: persons_by_pk(id: $personId) {
      ...personFields
    }
  }
  ${PERSON_FIELDS}
`;

const UPDATE_PERSON = gql`
  mutation UpdatePerson($personId: Int!, $formValues: persons_set_input) {
    update_persons(where: { id: { _eq: $personId } }, _set: $formValues) {
      returning {
        ...personFields
      }
    }
  }
  ${PERSON_FIELDS}
`;

const REMOVE_PERSON = gql`
  mutation RemovePerson($personId: Int!) {
    update_persons(
      where: { id: { _eq: $personId } }
      _set: { deleted: true, auth0_sub: null }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

function ClientPersonalInfo({ clientId }) {
  const { error, loading, data } = useQuery(GET_PERSON, {
    variables: { personId: clientId },
  });

  const auth0Roles = useAuth0Roles();

  const roles = auth0Roles
    .filter(r => ['manager', 'sales'].indexOf(r.name) !== -1)
    .map(r => r.id);

  const userData = useAuth0UsersByRole(roles);
  const [updatePerson] = useMutation(UPDATE_PERSON);
  const [removePerson] = useMutation(REMOVE_PERSON);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <>
      <ClientHeader title="Account settings" person={data.person} />
      <ContactPersons
        updatePerson={updatePerson}
        person={data.person}
        users={userData && userData}
      />
      <Credentials updatePerson={updatePerson} person={data.person} />
      <DeleteClient removePerson={removePerson} person={data.person} />
    </>
  );
}

ClientPersonalInfo.propTypes = {
  clientId: string.isRequired,
};

export default ClientPersonalInfo;
