import React from 'react';
import { string, shape, func } from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import NoCredentials from './noCredentials';
import HasCredentials from './hasCredentials';
import FormBox from '../../Form/FormBox';
import {
  useAuth0User,
  useAuth0Roles,
  CREATE_AUTH0_USER,
  DELETE_AUTH0_USER,
} from '../../../hooks/Auth0';
import { parseApolloError } from '../../../util/apollo';

function Credentials({ person, updatePerson }) {
  const auth0User = useAuth0User(person.auth0_sub);

  const [createAuth0User] = useMutation(CREATE_AUTH0_USER);
  const [deleteAuth0User, { loading: deleteAuth0UserLoading }] = useMutation(
    DELETE_AUTH0_USER,
  );
  const roles = useAuth0Roles();

  const onCreateCredentials = async ({ password, repeatPassword }) => {
    if (password !== repeatPassword) {
      return Promise.reject(new Error('Passwords do not match.'));
    }

    const clientRole = roles.find(r => r.name === 'client');

    if (!clientRole) {
      return Promise.reject(
        new Error('Could not find a suitable role for client, aborting.'),
      );
    }

    const createAuth0UserResponse = await createAuth0User({
      variables: {
        objects: [
          {
            password,
            email: person.email,
            family_name: person.last_name,
            given_name: person.first_name,
            role_id: clientRole.id,
          },
        ],
      },
    }).catch(e => {
      console.error(e);
      throw new Error(parseApolloError(e));
    });

    const {
      data: {
        insert_auth0_users: {
          returning: [{ user_id: sub }],
        },
      },
    } = createAuth0UserResponse;

    return updatePerson({
      variables: {
        personId: person.id,
        formValues: {
          auth0_sub: sub,
        },
      },
    }).catch(async () => {
      // unable to update local user data with new auth0 sub, revert
      // by deleting the auth0 user to avoid inconsistent state
      await deleteAuth0User({
        variables: {
          userSub: sub,
        },
      }).catch(e => {
        console.error(e);
      });
      throw new Error('Error creating credentials for user.');
    });
  };

  const onRevokeCredentials = async () => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you want to disallow access
to Mercurius Assurance for ${person.first_name} ${person.last_name}?

The user won't be informed of the revokation.`,
      )
    ) {
      return Promise.reject();
    }

    await deleteAuth0User({ variables: { userSub: auth0User.user_id } }).catch(
      e => {
        console.error(e);
        throw new Error(parseApolloError(e));
      },
    );

    return updatePerson({
      variables: {
        personId: person.id,
        formValues: {
          auth0_sub: null,
        },
      },
    });
  };

  return (
    <FormBox heading="Login credentials">
      {auth0User ? (
        <HasCredentials
          person={person}
          auth0User={auth0User}
          onRevoke={onRevokeCredentials}
          loading={deleteAuth0UserLoading}
        />
      ) : (
        <NoCredentials person={person} onSubmit={onCreateCredentials} />
      )}
    </FormBox>
  );
}

Credentials.propTypes = {
  person: shape({
    sub: string,
  }).isRequired,
  updatePerson: func.isRequired,
};

export default Credentials;
