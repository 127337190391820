import React, { useState } from 'react';
import { shape } from 'prop-types';
import { Flex, List, ListItem, Image } from '@chakra-ui/core';
import styled from '@emotion/styled';
import theme from '../../theme';
import { useAuth } from '../../util/auth';
import { NavLink as RouterNavLink } from '../../util/router';
import ArrowIcon from '../../assets/images/arrow.svg';
import ArrowDown from '../../assets/images/arrow_down.svg';

const NavLink = styled(RouterNavLink)`
  font-weight: normal;
  &.active {
    font-weight: bold;
  }
`;

const NavLinkOverview = styled(NavLink)(
  ({ 'data-menu-visible': isMenuVisible }) =>
    `
    display: ${!isMenuVisible ? 'none' : 'inline'};
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      display: inline;
    }
  `,
);

const MenuText = styled.span(
  ({ isMenuVisible }) =>
    `
    display: ${isMenuVisible ? 'none' : 'inline'};

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      display: none;
    }
  `,
);

const Nav = styled.nav`
  position: relative;
  background: #eef0f4;
  z-index: 5;
`;

const StyledListItem = styled(ListItem)`
  line-height: 1;
  padding: 1.5rem 1.25rem;
  border-bottom: 1px solid ${theme.colors['light-grey']};
`;

const ListItemTrigger = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`.withComponent(StyledListItem);

function ClientNavbar({ match }) {
  const { user } = useAuth();
  const [menuVisible, setMenuVisibility] = useState(false);

  const toggleClientNav = () => {
    setMenuVisibility(visibility => !visibility);
  };

  return (
    <Nav aria-label="secondary navigation">
      <List
        bg="sky"
        fontSize="xl"
        fontWeight="bold"
        maxH={{ base: menuVisible ? '100vh' : '4.25rem', xl: 'none' }}
        listStyleType="none"
        overflow="hidden"
        padding={{ base: '0 2rem', xl: '1.25rem 2rem' }}
        position="absolute"
        transition="max-height .5s ease-in-out"
        width="100%"
      >
        {['manager', 'sales'].includes(user.claims.role) && (
          <StyledListItem
            p="2.75rem 0 3.25rem"
            fontSize="1rem"
            display={{ base: 'none', xl: 'list-item' }}
          >
            <NavLink to="/clients">
              <Image
                src={ArrowIcon}
                alt="status"
                display="inline-block"
                marginRight="40px"
                verticalAlign="text-bottom"
              />
              All clients
            </NavLink>
          </StyledListItem>
        )}
        <ListItemTrigger onClick={toggleClientNav}>
          <NavLinkOverview
            exact
            to={`${match.url}`}
            data-menu-visible={menuVisible}
          >
            {['manager', 'sales'].includes(user.claims.role)
              ? 'Overview'
              : 'Dashboard'}
          </NavLinkOverview>
          <MenuText isMenuVisible={menuVisible}>Menu</MenuText>
          <Image
            src={ArrowDown}
            float="right"
            display={{ base: 'block', xl: 'none' }}
            transform={menuVisible ? 'rotateX(180deg)' : 'rotateX(0deg)'}
            transition="transform .3s ease-in-out"
          />
        </ListItemTrigger>
        <StyledListItem>
          <NavLink to={`${match.url}/profile`} onClick={toggleClientNav}>
            Personal info
          </NavLink>
        </StyledListItem>
        <StyledListItem>
          <NavLink to={`${match.url}/wealth`} onClick={toggleClientNav}>
            Wealth
          </NavLink>
        </StyledListItem>
        {['manager', 'sales'].includes(user.claims.role) && (
          <StyledListItem>
            <NavLink to={`${match.url}/notes`} onClick={toggleClientNav}>
              Notes
            </NavLink>
          </StyledListItem>
        )}
        <StyledListItem>
          <NavLink to={`${match.url}/policies`} onClick={toggleClientNav}>
            Policies
          </NavLink>
        </StyledListItem>
        {['manager', 'sales'].includes(user.claims.role) && (
          <StyledListItem>
            <NavLink to={`${match.url}/settings`} onClick={toggleClientNav}>
              Account settings
            </NavLink>
          </StyledListItem>
        )}
      </List>
    </Nav>
  );
}

ClientNavbar.propTypes = {
  match: shape({}).isRequired,
};

export default ClientNavbar;
