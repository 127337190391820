import React from 'react';
import { arrayOf, shape, number, func } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';

function ContactPersons({ person, updatePerson, users }) {
  const onSubmit = formValues => {
    return updatePerson({
      variables: {
        personId: person.id,
        formValues,
      },
    });
  };

  // modify the array of items into an object to give to react-select
  const options =
    users &&
    users.map(item => {
      return {
        value: item.user_id,
        label: item.name,
      };
    });

  return (
    <FormBox heading="Contact persons">
      <FormControls initialValues={person} onSubmit={onSubmit}>
        {({ editable }) => (
          <>
            <FormField
              label="Accountable person"
              editable={editable}
              fields={[
                {
                  name: 'accountable_id',
                  placeholder: 'Select person',
                  type: 'select',
                  options,
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
            <FormField
              label="Back up person"
              editable={editable}
              fields={[
                {
                  name: 'backup_person_id',
                  placeholder: 'Select person',
                  type: 'select',
                  options,
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

ContactPersons.propTypes = {
  person: shape({
    id: number,
  }).isRequired,
  updatePerson: func.isRequired,
  users: arrayOf(shape({})),
};
ContactPersons.defaultProps = {
  users: [],
};

export default ContactPersons;
