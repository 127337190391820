import React, { useState } from 'react';
import { shape, func, string } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import FormButton from '../../Form/FormButton';

function SpouseData({ title, person, updatePerson, addPerson }) {
  const [hasSpouse, setHasSpouse] = useState(!!person.spouse);

  const onSubmit = formValues => {
    if (person.spouse && person.spouse.id) {
      return updatePerson({
        variables: {
          personId: person.spouse.id,
          formValues,
        },
      });
    }

    return addPerson({
      variables: {
        formValues: {
          spouse_id: person.id,
          ...formValues,
        },
      },
    });
  };

  return (
    <FormBox heading={title}>
      {!hasSpouse && (
        <FormButton
          onClick={e => {
            e.preventDefault();
            setHasSpouse(true);
          }}
        >
          Add information
        </FormButton>
      )}

      {hasSpouse && (
        <FormControls
          initialValues={person.spouse}
          isInitiallyEditable={!person.spouse}
          onSubmit={onSubmit}
        >
          {({ editable }) => (
            <>
              <FormField
                label="First name"
                editable={editable}
                fields={[
                  {
                    name: 'first_name',
                    placeholder: 'First name',
                    validations: {
                      required: true,
                    },
                  },
                ]}
              />
              <FormField
                label="Last name"
                editable={editable}
                fields={[
                  {
                    name: 'last_name',
                    placeholder: 'Last name',
                    value: `${person.spouse && person.spouse.last_name}`,
                  },
                ]}
              />
              <FormField
                label="Personal id"
                editable={editable}
                fields={[
                  {
                    name: 'personal_id',
                    placeholder: 'Personal id',
                    value: `${person.spouse && person.spouse.personal_id}`,
                  },
                ]}
              />
              <FormField
                label="Date of birth"
                editable={editable}
                fields={[
                  {
                    name: 'date_of_birth',
                    placeholder: 'Date of birth (DD-MM-YYYY)',
                    type: 'date',
                    // validation for DD MM YYYY, dividers: (accepts . / -)
                    validations: {
                      message:
                        'Please enter the date in this format: DD-MM-YYYY',
                      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    },
                  },
                ]}
              />
              <FormField
                label="Nationality"
                editable={editable}
                fields={[
                  {
                    name: 'nationality',
                    placeholder: 'Nationality',
                    value: `${person.spouse && person.spouse.nationality}`,
                  },
                ]}
              />
              <FormField
                label="Taxation country"
                editable={editable}
                fields={[
                  {
                    name: 'taxation_country',
                    placeholder: 'Taxation country',
                    value: `${person.spouse && person.spouse.taxation_country}`,
                  },
                ]}
              />
              <FormField
                label="Address"
                editable={editable}
                fields={[
                  {
                    name: 'street_address',
                    placeholder: 'Street address',
                    value: `${person.spouse && person.spouse.street_address}`,
                  },
                  {
                    name: 'postal_code',
                    placeholder: 'Postal code',
                    value: `${person.spouse && person.spouse.postal_code}`,
                  },
                  {
                    name: 'city',
                    placeholder: 'City',
                    value: `${person.spouse && person.spouse.city}`,
                  },
                ]}
              />
              <FormField
                label="Phone number"
                editable={editable}
                fields={[
                  {
                    name: 'phone_number',
                    placeholder: 'Phone number',
                    value: `${person.spouse && person.spouse.phone_number}`,
                  },
                ]}
              />
              <FormField
                label="Marital status"
                editable={editable}
                fields={[
                  {
                    name: 'marital_status',
                    placeholder: 'Marital status',
                    value: `${person.spouse && person.spouse.marital_status}`,
                  },
                ]}
              />
            </>
          )}
        </FormControls>
      )}
    </FormBox>
  );
}

SpouseData.propTypes = {
  title: string,
  person: shape({
    spouse: shape({}),
  }).isRequired,
  updatePerson: func.isRequired,
  addPerson: func.isRequired,
};

SpouseData.defaultProps = {
  title: 'Spouse data',
};

export default SpouseData;
