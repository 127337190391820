import gql from 'graphql-tag';

const PERSON_FIELDS = gql`
  fragment childFields on persons {
    id
    first_name
    last_name
    personal_id
    date_of_birth
    nationality
    withexspouse
  }

  fragment personFields on persons {
    id
    email
    auth0_sub
    first_name
    last_name
    personal_id
    date_of_birth
    nationality
    taxation_country
    street_address
    postal_code
    city
    phone_number
    marital_status
    spouse_id
    parent_id
    withexspouse
    accountable_id
    backup_person_id
    spouse {
      id
      spouse_id
      first_name
      last_name
      personal_id
      date_of_birth
      nationality
      taxation_country
      street_address
      postal_code
      city
      phone_number
      marital_status
      children {
        ...childFields
      }
    }
    children {
      parent_id
      ...childFields
    }
    grandchildren {
      grandparent_id
      ...childFields
    }
  }
`;

export const GET_PERSON = gql`
  query getPerson($personId: Int!, $isNotClient: Boolean!) {
    person: persons_by_pk(id: $personId) {
      identification_received @include(if: $isNotClient)
      identification_type @include(if: $isNotClient)
      ...personFields
    }
  }
  ${PERSON_FIELDS}
`;

export const UPDATE_PERSON = gql`
  mutation UpdatePerson($personId: Int!, $formValues: persons_set_input) {
    update_persons(where: { id: { _eq: $personId } }, _set: $formValues) {
      returning {
        ...personFields
      }
    }
  }
  ${PERSON_FIELDS}
`;

export const ADD_PERSON = gql`
  mutation AddPerson($formValues: persons_insert_input!) {
    insert_persons(objects: [$formValues]) {
      returning {
        ...personFields
      }
    }
  }
  ${PERSON_FIELDS}
`;

export const DELETE_PERSON = gql`
  mutation DeletePerson($personId: Int!) {
    delete_persons(where: { id: { _eq: $personId } }) {
      returning {
        ...personFields
      }
    }
  }
  ${PERSON_FIELDS}
`;
