import React from 'react';
import { shape, number, func, bool, arrayOf, string } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import Insured from '../Insured';
import { statusOptions, typeOptions } from '../common';

function BasicInformation({
  onSubmit,
  policy,
  isInitiallyEditable,
  hideButtons,
}) {
  return (
    <FormBox heading="Basic information">
      <FormControls
        initialValues={policy}
        isInitiallyEditable={isInitiallyEditable}
        onSubmit={onSubmit}
        hideButtons={hideButtons}
      >
        {({ editable }) => (
          <>
            <FormField
              label="Policy provider"
              editable={editable}
              fields={[
                {
                  name: 'provider',
                  placeholder: 'Issuing company name',
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
            <FormField
              label="Policy number"
              editable={editable}
              fields={[
                {
                  name: 'number',
                  placeholder: 'Policy number',
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
            <FormField
              label="Policy holder"
              editable={editable}
              fields={[
                {
                  name: 'holder',
                  placeholder: 'Name of policy holder',
                },
              ]}
            />
            <Insured insured={policy && policy.insured} editable={editable} />
            <FormField
              label="Type"
              editable={editable}
              fields={[
                {
                  name: 'type',
                  placeholder: 'Select...',
                  type: 'select',
                  options: typeOptions,
                },
              ]}
            />
            <FormField
              label="Status"
              editable={editable}
              fields={[
                {
                  name: 'status',
                  placeholder: 'Select...',
                  type: 'select',
                  options: statusOptions,
                },
              ]}
            />
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

BasicInformation.propTypes = {
  onSubmit: func,
  policy: shape({
    id: number,
  }).isRequired,
  isInitiallyEditable: bool,
  hideButtons: arrayOf(string),
};

BasicInformation.defaultProps = {
  onSubmit: () => {},
  isInitiallyEditable: false,
  hideButtons: [],
};

export default BasicInformation;
