import React, { useState } from 'react';
import { Flex, Box, Button } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AuditLogRow from './AuditLogRow';
import Section from '../Section';
import Container from '../Container';
import Loading from '../Loading';
import Error from '../Error';
import { H3 } from '../Typography';

const AUDIT_LOG_FIELDS = gql`
  fragment auditLogFields on audit_logged_actions {
    action
    table_name
    row_data
    changed_fields
    hasura_user
    action_tstamp_tx
  }
`;

const GET_AUDIT_LOG = gql`
  query getAuditLog {
    audit_logged_actions_aggregate(
      order_by: { action_tstamp_tx: desc }
      limit: 100
    ) {
      aggregate {
        count
      }
      nodes {
        ...auditLogFields
      }
    }
  }
  ${AUDIT_LOG_FIELDS}
`;

function AuditLog() {
  const [showLimit, setShowLimit] = useState(10);

  const { error, loading, data } = useQuery(GET_AUDIT_LOG);

  const fetchMore = () => {
    setShowLimit(prev => prev + 5);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <Section>
      <Container seamless>
        <Flex
          bg="white"
          flexDir="column"
          p={{ base: '3rem 1rem', sm: '3rem 2.5rem' }}
        >
          <H3 as="h2">Activity</H3>
          {data.audit_logged_actions_aggregate.nodes.length !== 0 ? (
            data.audit_logged_actions_aggregate.nodes.map(
              (log, i) => i < showLimit && <AuditLogRow key={i} log={log} />,
            )
          ) : (
            <Box>There's no new activity</Box>
          )}
          {showLimit < data.audit_logged_actions_aggregate.aggregate.count && (
            <Flex justifyContent="center" p="1.5rem 0 0">
              <Button
                bg="white"
                fontWeight="bold"
                fontSize="xl"
                onClick={fetchMore}
              >
                See more
              </Button>
            </Flex>
          )}
        </Flex>
      </Container>
    </Section>
  );
}

export default AuditLog;
