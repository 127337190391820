import React, { useState } from 'react';
import { arrayOf, string, shape, bool, func } from 'prop-types';
import useForm, { FormContext } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/core';
import moment from 'moment';
import FormButtons from '../FormButtons';

function FormControls({
  initialValues,
  isInitiallyEditable,
  onSubmit,
  onCancel = () => {},
  onDelete,
  hideButtons,
  children,
}) {
  const convertDate = dateString => {
    // check what is the date format
    const parts = dateString.split(/[.\/-]/);
    let initialDate;
    if (parts[0].length > 2) {
      initialDate = moment(dateString, 'YYYY-MM-DD');
    } else {
      initialDate = moment(dateString, 'DD-MM-YYYY');
    }
    return initialDate;
  };

  // convert date to wanted form format (DD-MM-YYYY)
  if (initialValues) {
    initialValues.date_of_birth =
      initialValues.date_of_birth &&
      moment(convertDate(initialValues.date_of_birth)).format('DD-MM-YYYY');
    initialValues.payment_due_date =
      initialValues.payment_due_date &&
      moment(convertDate(initialValues.payment_due_date)).format('DD-MM-YYYY');
    initialValues.expiration_date =
      initialValues.expiration_date &&
      moment(convertDate(initialValues.expiration_date)).format('DD-MM-YYYY');
    initialValues.start_date =
      initialValues.start_date &&
      moment(convertDate(initialValues.start_date)).format('DD-MM-YYYY');
    initialValues.last_valuation_date =
      initialValues.last_valuation_date &&
      moment(convertDate(initialValues.last_valuation_date)).format(
        'DD-MM-YYYY',
      );
  }

  const formMethods = useForm({
    defaultValues: {
      ...initialValues,
    },
  });

  const { handleSubmit, reset } = formMethods;
  const [editable, setEditable] = useState(isInitiallyEditable);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState();

  const submitHandler = async formValues => {
    // convert date to Postgres format (YYYY-MM-DD)
    if (formValues.date_of_birth || formValues.date_of_birth === '') {
      formValues.date_of_birth = formValues.date_of_birth
        ? moment(convertDate(formValues.date_of_birth)).format('YYYY-MM-DD')
        : null;
    }
    if (formValues.payment_due_date || formValues.payment_due_date === '') {
      formValues.payment_due_date = formValues.payment_due_date
        ? moment(convertDate(formValues.payment_due_date)).format('YYYY-MM-DD')
        : null;
    }
    if (formValues.expiration_date || formValues.expiration_date === '') {
      formValues.expiration_date = formValues.expiration_date
        ? moment(convertDate(formValues.expiration_date)).format('YYYY-MM-DD')
        : null;
    }
    if (formValues.start_date || formValues.start_date === '') {
      formValues.start_date = formValues.start_date
        ? moment(convertDate(formValues.start_date)).format('YYYY-MM-DD')
        : null;
    }
    if (
      formValues.last_valuation_date ||
      formValues.last_valuation_date === ''
    ) {
      formValues.last_valuation_date = formValues.last_valuation_date
        ? moment(convertDate(formValues.last_valuation_date)).format(
            'YYYY-MM-DD',
          )
        : null;
    }

    setSaving(true);
    setSaved(false);
    setErrors('');
    onSubmit(formValues)
      .then(() => {
        setEditable(false);
        setSaving(false);
        setSaved(true);
      })
      .catch(e => {
        setSaving(false);
        setErrors(e.message);
      });
  };

  const resetHandler = () => {
    onCancel();
    reset(initialValues);
    setEditable(false);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormContext {...formMethods}>
      {!editable && !hideButtons.includes('edit') && (
        <Flex align="baseline" justify="flex-end">
          <Button
            pos={{ base: 'static', lg: 'absolute' }}
            top="4rem"
            marginBottom={{ base: '2rem', lg: '0' }}
            variant="ghost"
            fontWeight="bold"
            onClick={() => setEditable(true)}
          >
            Edit
          </Button>
        </Flex>
      )}
      <form className={`${editable ? 'editable' : ''}`}>
        {children({
          editable,
          submitForm: handleSubmit(submitHandler),
          submitting: saving,
          submitted: saved,
          ...formMethods,
        })}
        {editable && (
          <FormButtons
            submitting={saving}
            editable={editable}
            onSubmit={handleSubmit(submitHandler)}
            onCancel={resetHandler}
            onDelete={onDelete}
            errors={errors}
            hideButtons={hideButtons}
          />
        )}
      </form>
      {/*
      <pre>{JSON.stringify(values, null, 2)}</pre>
       */}
    </FormContext>
  );
}

FormControls.propTypes = {
  initialValues: shape({}),
  isInitiallyEditable: bool,
  onSubmit: func.isRequired,
  onCancel: func,
  onDelete: func,
  hideButtons: arrayOf(string), // edit, save, cancel, delete
  children: func.isRequired,
};
FormControls.defaultProps = {
  initialValues: {},
  isInitiallyEditable: false,
  onCancel: () => {},
  hideButtons: [],
  onDelete: null,
};

export default FormControls;
