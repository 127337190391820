import React from 'react';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth } from './auth';
import { API_URL } from '../config';

function ApolloProviderProvider({ children }) {
  const { loading, token } = useAuth();

  if (loading) {
    return 'Authenticating...';
  }

  const httpLink = createHttpLink({
    uri: `${API_URL}/v1/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export const parseApolloError = apolloError =>
  apolloError.graphQLErrors
    .map(e => e.message)
    .concat(apolloError.networkError)
    .filter(Boolean)
    .join(', ');

export default ApolloProviderProvider;
