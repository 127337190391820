import React from 'react';
import { shape } from 'prop-types';
import ClientList from '../../components/ClientList';
import ClientProfile from '../../components/ClientProfile';
import NewClient from '../../components/NewClient';
import { useAuth } from '../../util/auth';
import { Switch, Route, ProtectedRoute } from '../../util/router';
import Loading from '../../components/Loading';

/**
 * Renders the high level clients section for admin persons.
 *
 * Sub-routes:
 * /                Initial view is a client listing
 * /new             Add new client
 * /:id             Client summary view (internal notes etc)
 * /:id/profile     Client personal info
 * /:id/wealth      Client wealth info
 */
function ClientsPage({ match }) {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Route exact path={`${match.url}/`} component={ClientList} />
      <Switch>
        <ProtectedRoute
          exact
          path={`${match.url}/new`}
          component={NewClient}
          roles={['manager', 'sales']}
        />
        <Route path={`${match.url}/:clientId`} component={ClientProfile} />
      </Switch>
    </>
  );
}

ClientsPage.propTypes = {
  match: shape({}).isRequired,
};

export default ClientsPage;
