import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';
import { node } from 'prop-types';
import loadGoogleApi from 'google-api-load';
import sortBy from 'lodash.sortby';

import { AuthContext } from './auth';

export const GoogleAPIContext = createContext();

const googleApiBaseConfig = {
  clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
  apiKey: process.env.REACT_APP_GOOGLE_API_API_KEY,
  scope: 'https://www.googleapis.com/auth/tasks',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/tasks/v1/rest'],
};

const GoogleTasks = ({ children }) => {
  const gapi = useRef();
  const [loading, setLoading] = useState(true);
  const [tasksList, setTaskList] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [error, setError] = useState(null);

  const { isAuthenticated, user } = useContext(AuthContext);

  const onStatusUpdate = async status => {
    if (status) {
      setLoading(true);
      const listsResponse = await gapi.current.client.tasks.tasklists.list({
        maxResults: 10,
      });

      const lists = listsResponse.result.items;
      setTaskList(lists[0].id);

      if (lists && lists.length) {
        lists.forEach(async ({ id: tasklist }) => {
          const tasksResponse = await gapi.current.client.tasks.tasks.list({
            showCompleted: true,
            showHidden: true,
            maxResults: 10,
            tasklist,
          });
          setLoading(false);

          setNextPageToken(tasksResponse.result.nextPageToken);
          setTasks(sortBy(tasksResponse.result.items, ['due']));
        });
      }
    }
  };

  const handleLoadMore = async () => {
    setLoading(true);
    const tasksResponse = await gapi.current.client.tasks.tasks.list({
      showCompleted: true,
      showHidden: true,
      tasklist: tasksList,
      maxResults: 10,
      pageToken: nextPageToken,
    });
    setLoading(false);

    setNextPageToken(tasksResponse.result.nextPageToken);
    setTasks(prevTasks =>
      sortBy([...tasksResponse.result.items, ...prevTasks], ['due']),
    );
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      (user.claims.role === 'manager' || user.claims.role === 'sales')
    ) {
      loadGoogleApi({
        auth: {
          ...googleApiBaseConfig,
          listener: onStatusUpdate,
        },
      }).then(
        GoogleAPI => {
          gapi.current = GoogleAPI;
        },
        err => setError(err.error),
      );
    }
  }, [isAuthenticated, user]);

  return (
    <GoogleAPIContext.Provider
      value={{
        gapi: gapi.current,
        tasksList,
        tasks,
        handleLoadMore,
        loading,
        nextPageToken,
        error,
      }}
    >
      {children}
    </GoogleAPIContext.Provider>
  );
};

GoogleTasks.propTypes = {
  children: node.isRequired,
};

export default GoogleTasks;
