import React from 'react';
import { shape, string } from 'prop-types';

function NotFound({ location }) {
  return (
    <div
      style={{
        padding: '50px',
        width: '100%',
        textAlign: 'center',
        color: '#fff',
      }}
    >
      The page <code>{location.pathname}</code> could not be found.
    </div>
  );
}

NotFound.propTypes = {
  location: shape({
    pathname: string,
  }).isRequired,
};

export default NotFound;
