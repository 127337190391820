import React, { useState } from 'react';
import { string } from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@chakra-ui/core';
import Section from '../../Section';
import Container from '../../Container';
import { H2 } from '../../Typography';
import { Redirect } from '../../../util/router';
import Error from '../../Error';
import BasicInformation from '../BasicInformation';

const ADD_POLICY = gql`
  mutation AddPolicy($formValues: person_policies_insert_input!) {
    insert_person_policies(objects: [$formValues]) {
      returning {
        id
      }
    }
  }
`;

const ADD_INSURED = gql`
  mutation AddInsured($insuredValues: insured_insert_input!) {
    insert_insured(objects: [$insuredValues]) {
      returning {
        id
      }
    }
  }
`;

function NewPolicy({ clientId }) {
  const [addPolicy] = useMutation(ADD_POLICY);
  const [addInsured] = useMutation(ADD_INSURED);
  const [policyId, setPolicyId] = useState();
  const [saveError, setSaveError] = useState();

  const onSubmit = async submitValues => {
    const formValues = {
      person_id: Number(clientId),
    };
    Object.keys(submitValues).forEach(key => {
      if (key !== 'insured') {
        formValues[key] = submitValues[key];
      }
    });

    addPolicy({
      variables: {
        formValues,
      },
    })
      .then(result => {
        const promises = submitValues.insured
          .filter(item => !!item.name)
          .map(item => {
            const insuredValues = {
              name: item.name,
              policy_id: result.data.insert_person_policies.returning['0'].id,
            };

            return addInsured({
              variables: {
                insuredValues,
              },
            });
          });
        return Promise.all(promises).then(() => {
          setPolicyId(result.data.insert_person_policies.returning['0'].id);
        });
      })
      .catch(error => {
        if (error.message) {
          setSaveError(error.message);
        } else {
          setSaveError('Unable to create a new policy');
        }
      });
  };

  if (policyId) {
    return <Redirect to={`/clients/${clientId}/policies/${policyId}`} />;
  }

  if (saveError) {
    return (
      <Section>
        <Container>
          <Error message={saveError} />
        </Container>
      </Section>
    );
  }

  return (
    <>
      <Box
        p={{ base: '6rem 2.5rem 0', sm: '7rem 2.5rem 0' }}
        marginBottom="3.75rem"
      >
        <H2 as="h1" m={0}>
          Add new policy
        </H2>
      </Box>
      <BasicInformation
        onSubmit={onSubmit}
        isInitiallyEditable
        hideButtons={['cancel']}
        policy={{}}
      />
    </>
  );
}

NewPolicy.propTypes = {
  clientId: string.isRequired,
};

export default NewPolicy;
