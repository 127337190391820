import React, { useState, useEffect, useContext } from 'react';
import {
  Flex,
  Spinner,
  List,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Text,
} from '@chakra-ui/core';
import styled from '@emotion/styled';

import Task from '../Task';
import { H4 } from '../Typography';
import { formatDate } from '../../util/helpers';
import { GoogleAPIContext } from '../../util/googleTasks';

const StyledTab = styled(Tab)`
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #ffffff;
  margin-bottom: 1rem;
  width: 100px;
  min-width: 65px;
  max-width: 47.5%;
  background-color: transparent !important;

  &[aria-selected='true'] {
    border-color: currentColor;
  }
`;

export default () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [noTasks, setNoTasks] = useState(true);
  const [date, setDate] = useState(null);
  const {
    gapi,
    tasksList,
    tasks,
    handleLoadMore,
    loading,
    nextPageToken,
    error,
  } = useContext(GoogleAPIContext);

  const handleChange = async (e, taskId) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });

    await gapi.client.tasks.tasks.update({
      tasklist: tasksList,
      task: taskId,
      resource: {
        id: taskId,
        status: e.target.checked ? 'completed' : 'needsAction',
      },
    });
  };

  useEffect(() => {
    const today = new Date();
    setDate(today);
  }, []);

  useEffect(() => {
    if (!!tasks && tasks.length) {
      tasks.forEach(task => {
        if (task.status === 'needsAction') setNoTasks(false);
      });
    }
  }, [tasks]);
  return (
    <Flex direction="column">
      <Tabs variant="solid-rounded" color="white" isFitted>
        <Flex justifyContent="space-between" alignItems="center" p="0 0 1.5rem">
          <H4 color="white" mb="0">
            {formatDate(Date.parse(date))}
          </H4>
          <TabList justifyContent="space-between" flexWrap="wrap">
            <StyledTab>Due</StyledTab>
            <StyledTab>Done</StyledTab>
          </TabList>
        </Flex>
        <TabPanels mt={4} outline="none">
          {/* Due Panel */}
          <TabPanel>
            {!loading && noTasks && (
              <Text>
                No tasks found. Create new tasks in your Google Calendar.
              </Text>
            )}
            {loading && <Spinner color="white" thickness="1px" speed="0.28s" />}

            {error && <Text color="white">{error}</Text>}

            <List>
              {!!tasks &&
                tasks.map(
                  task =>
                    task.status === 'needsAction' &&
                    !checkedItems[task.id] && (
                      <Task
                        key={task.id}
                        handleChange={handleChange}
                        checkedItems={checkedItems}
                        task={task}
                        today={formatDate(Date.parse(date))}
                      />
                    ),
                )}
              {tasks.length && nextPageToken && !noTasks && (
                <Button
                  onClick={handleLoadMore}
                  color="nude"
                  isLoading={loading}
                  fontSize="sm"
                >
                  More
                </Button>
              )}
            </List>
          </TabPanel>
          <TabPanel>
            {/* Done Panel */}
            {loading && <Spinner color="white" thickness="1px" speed="0.28s" />}

            <List>
              {!!tasks &&
                tasks.map(
                  task =>
                    (checkedItems[task.id] || task.status === 'completed') && (
                      <Task
                        key={task.id}
                        handleChange={handleChange}
                        checkedItems={checkedItems}
                        task={task}
                      />
                    ),
                )}
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
