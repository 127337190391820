import gql from 'graphql-tag';

const NOTE_FIELDS = gql`
  fragment noteFields on person_notes {
    id
    person_id
    content
    author_id
    created_at
    updated_at
  }
`;

export const GET_PERSON_NOTES = gql`
  query getPersonNotes($personId: Int!) {
    person_notes(
      where: { person_id: { _eq: $personId } }
      order_by: { updated_at: desc }
    ) {
      ...noteFields
    }
    persons(where: { id: { _eq: $personId } }) {
      first_name
      last_name
      city
      email
      phone_number
      date_of_birth
    }
  }
  ${NOTE_FIELDS}
`;

export const ADD_PERSON_NOTE = gql`
  mutation AddPersonNote($formValues: person_notes_insert_input!) {
    insert_person_notes(objects: [$formValues]) {
      returning {
        ...noteFields
      }
    }
  }
  ${NOTE_FIELDS}
`;

export const UPDATE_PERSON_NOTE = gql`
  mutation UpdatePersonNote(
    $noteId: Int!
    $formValues: person_notes_set_input
  ) {
    update_person_notes(where: { id: { _eq: $noteId } }, _set: $formValues) {
      returning {
        ...noteFields
      }
    }
  }
  ${NOTE_FIELDS}
`;

export const DELETE_PERSON_NOTE = gql`
  mutation DeletePersonNote($id: Int!) {
    delete_person_notes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
