import gql from 'graphql-tag';

export const POLICY_FIELDS = gql`
  fragment insuredFields on insured {
    id
    name
    policy_id
    created_at
    updated_at
  }

  fragment beneficiaryFields on beneficiaries {
    id
    name
    policy_id
    created_at
    updated_at
    date_of_birth
    relationship
    percentage_amount
    notes
  }

  fragment assetManagerFields on asset_managers {
    id
    name
    policy_id
    created_at
    updated_at
    firm
  }

  fragment policyFields on person_policies {
    holder
    id
    number
    provider
    status
    type
    person_id
    start_date
    expiration_date
    risk_cover_amount
    risk_cover_currency
    original_investment_amount
    most_recent_value
    last_valuation_date
    payment_due_date
    payment_amount
    payment_currency
    pricing_description
    insured(order_by: { id: asc }) {
      ...insuredFields
    }
    beneficiaries(order_by: { id: asc }) {
      ...beneficiaryFields
    }
    asset_managers(order_by: { id: asc }) {
      ...assetManagerFields
    }
  }
`;

export const GET_POLICY = gql`
  query GetPolicy($policyId: Int!) {
    policy: person_policies_by_pk(id: $policyId) {
      ...policyFields
    }
  }
  ${POLICY_FIELDS}
`;

export const UPDATE_POLICY = gql`
  mutation UpdatePolicy(
    $policyId: Int!
    $formValues: person_policies_set_input
  ) {
    update_person_policies(
      where: { id: { _eq: $policyId } }
      _set: $formValues
    ) {
      returning {
        ...policyFields
      }
    }
  }
  ${POLICY_FIELDS}
`;

export const ADD_INSURED = gql`
  mutation AddInsured($insuredValues: insured_insert_input!) {
    insert_insured(objects: [$insuredValues]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_INSURED = gql`
  mutation UpdateInsured($insuredId: Int!, $insuredValues: insured_set_input!) {
    update_insured(where: { id: { _eq: $insuredId } }, _set: $insuredValues) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_INSURED = gql`
  mutation DeleteInsured($insuredId: Int!) {
    delete_insured(where: { id: { _eq: $insuredId } }) {
      affected_rows
    }
  }
`;

export const GET_POLICIES = gql`
  query getPolicies(
    $order_by: [person_policies_order_by!]
    $search: String!
    $where: person_policies_bool_exp
    $limit: Int!
    $offset: Int!
  ) {
    policies: search_policies(
      where: $where
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      holder
      number
      provider
      status
      type
      person_id
      start_date
      expiration_date
      risk_cover_amount
      risk_cover_currency
      original_investment_amount
      most_recent_value
      last_valuation_date
      payment_due_date
      payment_amount
      payment_currency
      pricing_description
      insured {
        id
        name
        policy_id
        created_at
        updated_at
      }
    }
  }
`;

export const GET_POLICY_TYPES = gql`
  query {
    person_policies(distinct_on: [type], order_by: { type: asc }) {
      id
      type
    }
  }
`;

export const ADD_BENEFICIARY = gql`
  mutation AddBeneficiary($formValues: beneficiaries_insert_input!) {
    insert_beneficiaries(objects: [$formValues]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_BENEFICIARY = gql`
  mutation UpdateBeneficiary(
    $beneficiaryId: Int!
    $formValues: beneficiaries_set_input!
  ) {
    update_beneficiaries(
      where: { id: { _eq: $beneficiaryId } }
      _set: $formValues
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_BENEFICIARY = gql`
  mutation DeleteBeneficiary($beneficiaryId: Int!) {
    delete_beneficiaries(where: { id: { _eq: $beneficiaryId } }) {
      affected_rows
    }
  }
`;

export const ADD_ASSET_MANAGER = gql`
  mutation AddAssetManager($assetManagerValues: asset_managers_insert_input!) {
    insert_asset_managers(objects: [$assetManagerValues]) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ASSET_MANAGER = gql`
  mutation UpdateAssetManager(
    $assetManagerId: Int!
    $assetManagerValues: asset_managers_set_input!
  ) {
    update_asset_managers(
      where: { id: { _eq: $assetManagerId } }
      _set: $assetManagerValues
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_ASSET_MANAGER = gql`
  mutation DeleteAssetManager($assetManagerId: Int!) {
    delete_asset_managers(where: { id: { _eq: $assetManagerId } }) {
      affected_rows
    }
  }
`;

export const GET_POLICY_COVERAGE = gql`
  query getCoverage($clientId: Int!) {
    get_policy_coverage(where: { person_id: { _eq: $clientId } }) {
      person_id
      total
      type
    }
  }
`;
