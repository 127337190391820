import React, { useState, useEffect } from 'react';
import { shape, arrayOf, func, string } from 'prop-types';
import { useAuth } from '../../../util/auth';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import FormButton from '../../Form/FormButton';

const relationshipOptions = [
  { value: 'spouse', label: 'Spouse' },
  { value: 'child', label: 'Child' },
  { value: 'grandchild', label: 'Grandchild' },
  { value: 'parent', label: 'Parent' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'extended_family', label: 'Extended family' },
  { value: 'business_partner', label: 'Business partner' },
  { value: 'other', label: 'Other' },
];

function Beneficiaries({
  beneficiaryData,
  addBeneficiary,
  updateBeneficiary,
  deleteBeneficiary,
  hideButtons,
}) {
  const { user } = useAuth();
  const [beneficiaryCount, setBeneficiaryCount] = useState(
    beneficiaryData.length,
  );

  useEffect(() => {
    setBeneficiaryCount(beneficiaryData.length);
  }, [beneficiaryData]); // eslint-disable-line

  const onSubmit = ({ id, ...formValues }) => {
    if (id) {
      return updateBeneficiary(id, formValues);
    }
    return addBeneficiary(formValues);
  };

  const onCancel = i => {
    if (!beneficiaryData[i]) {
      setBeneficiaryCount(prev => prev - 1);
    }
  };

  const onDelete = id => {
    return deleteBeneficiary(id);
  };

  const addNewBeneficiary = () => {
    setBeneficiaryCount(prev => prev + 1);
  };

  return (
    <FormBox heading="Beneficiaries">
      {Array.from({ length: beneficiaryCount }, (_, i) => i).map(i => (
        <FormControls
          key={(beneficiaryData[i] || {}).id || `new_${i}`}
          initialValues={beneficiaryData[i]}
          isInitiallyEditable={!beneficiaryData[i]}
          onSubmit={onSubmit}
          onCancel={() => onCancel(i)}
          onDelete={
            beneficiaryData[i] && (() => onDelete(beneficiaryData[i].id))
          }
          hideButtons={hideButtons}
        >
          {({ editable }) => (
            <>
              <FormField
                label={`Beneficiary ${i + 1}`}
                editable={editable}
                fields={[
                  {
                    name: 'name',
                    placeholder: 'Beneficiary name',
                    validations: {
                      required: true,
                    },
                  },
                  {
                    name: 'id',
                    type: 'hidden',
                    value: beneficiaryData[i] && beneficiaryData[i].id,
                  },
                ]}
              />
              <FormField
                label="Date of birth"
                editable={editable}
                fields={[
                  {
                    name: 'date_of_birth',
                    placeholder: 'Date of birth (DD-MM-YYYY)',
                    type: 'date',
                    // validation for DD MM YYYY, dividers: (accepts . / -)
                    validations: {
                      message:
                        'Please enter the date in this format: DD-MM-YYYY',
                      pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                    },
                  },
                ]}
              />
              <FormField
                label="Relationship"
                editable={editable}
                fields={[
                  {
                    name: 'relationship',
                    placeholder: 'Select...',
                    type: 'select',
                    options: relationshipOptions,
                  },
                ]}
              />
              <FormField
                label="Percentage/amount"
                editable={editable}
                fields={[
                  {
                    name: 'percentage_amount',
                    placeholder: 'Percentage or amount of benefit',
                  },
                ]}
              />
              <FormField
                label="Notes"
                editable={editable}
                fields={[
                  {
                    name: 'notes',
                    placeholder: 'Notes, rules, or conditions applying to this beneficiary',
                    type: 'textarea',
                  },
                ]}
              />
            </>
          )}
        </FormControls>
      ))}
      {['manager', 'sales'].includes(user.claims.role) && (
        <FormButton onClick={addNewBeneficiary}>Add new</FormButton>
      )}
    </FormBox>
  );
}

Beneficiaries.propTypes = {
  beneficiaryData: arrayOf(shape({})).isRequired,
  addBeneficiary: func.isRequired,
  updateBeneficiary: func.isRequired,
  deleteBeneficiary: func.isRequired,
  hideButtons: arrayOf(string),
};

Beneficiaries.defaultProps = {
  hideButtons: [],
};

export default Beneficiaries;
