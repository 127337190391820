import React from 'react';
import { shape, number, func, arrayOf, string } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';

function PaymentInformation({ onSubmit, policy, hideButtons }) {
  return (
    <FormBox heading="Payment information">
      <FormControls
        initialValues={policy}
        onSubmit={onSubmit}
        hideButtons={hideButtons}
      >
        {({ editable }) => (
          <>
            <FormField
              label="Payment due date"
              editable={editable}
              fields={[
                {
                  name: 'payment_due_date',
                  placeholder: 'Payment due date (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
              ]}
            />
            <FormField
              label="Payment amount"
              editable={editable}
              fields={[
                {
                  name: 'payment_amount',
                  type: 'monetary',
                  currency: 'EUR',
                  placeholder: 'Payment amount',
                  validations: {
                    min: 0,
                  },
                },
              ]}
            />
            <FormField
              label="Currency"
              editable={editable}
              fields={[
                {
                  name: 'payment_currency',
                  placeholder: 'Default: EUR',
                },
              ]}
            />
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

PaymentInformation.propTypes = {
  onSubmit: func,
  policy: shape({
    id: number,
  }).isRequired,
  hideButtons: arrayOf(string),
};

PaymentInformation.defaultProps = {
  onSubmit: () => {},
  policy: {},
  hideButtons: [],
};

export default PaymentInformation;
