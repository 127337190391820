import React from 'react';
import { number } from 'prop-types';
import styled from '@emotion/styled';
import { Box, Flex, Text } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { Link } from '../../../util/router';
import theme from '../../../theme';
import Section from '../../Section';
import Container from '../../Container';
import Loading from '../../Loading';
import Error from '../../Error';
import { GET_POLICIES } from '../queries';
import { typeOptions } from '../common';
import { ReactComponent as StatusIcon } from '../../../assets/images/circle.svg';

const propTypes = {
  clientId: number.isRequired,
};

const Status = styled(StatusIcon)`
  display: inline-block;
  margin-left: 15px;

  &.active {
    #Connect-Client-list {
      fill: ${theme.colors.active};
    }
  }

  &.inactive,
  &.expired {
    #Connect-Client-list {
      fill: ${theme.colors.danger};
    }
  }

  &.pending {
    #Connect-Client-list {
      fill: ${theme.colors.warning};
    }
  }

  &.other {
    #Connect-Client-list {
      fill: ${theme.colors.space};
    }
  }
`;

function MyPoliciesList({ match, clientId }) {
  let offset = 0;

  const { loading, error, data } = useQuery(GET_POLICIES, {
    variables: {
      order_by: {
        type: 'asc',
      },
      limit: 10,
      offset,
      search: '',
      where: {
        person_id: { _eq: clientId },
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <Section>
        <Container>
          <Error />
        </Container>
      </Section>
    );
  }

  return (
    <Flex
      direction="column"
      mt="50px"
      overflowY="auto"
      p={{ base: '0 1rem 10px', sm: '0 0 10px' }}
    >
      {data && data.policies && data.policies.length !== 0 && (
        <>
        <Text fontWeight="bold">
          Your policies
        </Text>
        <table>
          <thead>
            <tr>
              <Box as="th">
                Product
              </Box>
              <Box as="th">
                Valid until
              </Box>
              <Box as="th">Coverage</Box>
              <Box
                as="th"
                d={{ base: 'none', lg: 'table-cell' }}
                textAlign="right"
              >
                Status
              </Box>
            </tr>
          </thead>
  
          <tbody>
            {!!data &&
              data.policies &&
              data.policies.map(policy => (
                <tr key={policy.id}>
                  <Box as="td" fontWeight="bold">
                    <Link to={`${match.url}/policies/${policy.id}`}>
                      {typeOptions.map(
                        option =>
                          option.value === policy.type && option.label,
                      )}
                    </Link>
                  </Box>
                  <Box as="td">
                    <Link to={`${match.url}/policies/${policy.id}`}>
                      {policy.expiration_date}
                    </Link>
                  </Box>
                  <Box as="td">
                    <Link to={`${match.url}/policies/${policy.id}`}>
                      {policy.risk_cover_amount &&
                            `${policy.risk_cover_amount} ${policy.risk_cover_currency}`}
                    </Link>
                  </Box>
  
                  <Box
                    as="td"
                    d={{ base: 'none', lg: 'table-cell' }}
                    textAlign="right"
                    textTransform="capitalize"
                  >
                    {policy.status && policy.status}
                    {policy.status && <Status className={policy.status} />}
                  </Box>
                </tr>
              ))}
          </tbody>
        </table>
        </>
      )}
    </Flex>
  );
}

MyPoliciesList.propTypes = propTypes;

export default MyPoliciesList;
