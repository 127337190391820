import React, { useEffect, forwardRef } from 'react';
import { func, string } from 'prop-types';
import { Input, Icon, InputGroup, InputRightElement } from '@chakra-ui/core';

const Search = forwardRef(({ keyword, handleSearch, ...props }, ref) => {
  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  return (
    <InputGroup maxW="400px">
      <Input
        ref={ref}
        placeholder="Search"
        value={keyword}
        onChange={event => handleSearch(event)}
        {...props}
      />
      <InputRightElement children={<Icon name="search-2" color="black" />} />
    </InputGroup>
  );
});

Search.defaultProps = {
  keyword: '',
};

Search.prototypes = {
  keyword: string,
  handleSearch: func.isRequired,
};

export default Search;
