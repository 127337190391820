import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/core';
import { shape } from 'prop-types';
import { useAuth } from '../../util/auth';
import { Redirect } from '../../util/router';
import Dashboard from '../../components/Dashboard';
import ContentOutput from '../../components/ContentOutput';
import { toggleBodyClass } from '../../util/helpers';
import Loading from '../../components/Loading';
import Section from '../../components/Section';
import Container from '../../components/Container';
import { H2 } from '../../components/Typography';

function HomePage({ match }) {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth();

  useEffect(() => {
    // mount
    toggleBodyClass('dashboard');

    return () => {
      // unmount
      toggleBodyClass('dashboard', true);
    };
  }, []);

  if (isAuthenticated && ['client'].includes(user.claims.role)) {
    return <Redirect to="/u" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isAuthenticated ? (
        <Dashboard match={match} />
      ) : (
        <Section className="home-section">
          <Container margin={{ base: '0 1.5rem', sm: '0' }}>
            <Box>
              <H2
                as="h2"
                margin={{ base: '2rem 0', sm: '7rem 0 3rem' }}
                maxW="800px"
                color="nude"
                fontFamily="secondary"
              >
                Please press “Sign In” and enter your login credentials.
              </H2>
              <ContentOutput fontSize="xl" color="nude">
                If you’re looking for information on Mercurius Assurance, please
                visit our main site at{' '}
                <a href="https://mercuriusassurance.com">
                  mercuriusassurance.com
                </a>
              </ContentOutput>
              <Button
                className="m-button"
                marginTop="3rem"
                onClick={() => {
                  loginWithRedirect({});
                }}
              >
                Sign In
              </Button>
            </Box>
          </Container>
        </Section>
      )}
    </>
  );
}

HomePage.propTypes = {
  match: shape({}).isRequired,
};

export default HomePage;
