import React, { useState, useEffect } from 'react';
import { arrayOf, shape, func, string } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import FormButton from '../../Form/FormButton';

function Children({ title, childData, addChild, updateChild, deleteChild }) {
  const [childCount, setChildCount] = useState(childData.length);

  useEffect(() => {
    setChildCount(childData.length);
  }, [childData]); // eslint-disable-line

  const onSubmit = ({ id, ...formValues }) => {
    if (id) {
      return updateChild(id, formValues);
    }
    return addChild(formValues);
  };

  // TODO: this causes problems when adding
  // mutliple children at once and canceling
  // a form that is not at the bottom. I.e.
  //
  // - click add new
  // - click add new
  // - click add new
  // - click cancel on the first or second open form
  const onCancel = i => {
    if (!childData[i]) {
      setChildCount(prev => prev - 1);
    }
  };

  const onDelete = personId => {
    return deleteChild(personId);
  };

  const addNewChild = () => {
    setChildCount(prev => prev + 1);
  };

  return (
    <FormBox heading={title}>
      {Array.from({ length: childCount }, (_, i) => i).map(i => (
        <FormControls
          key={(childData[i] || {}).id || `new_${i}`}
          initialValues={childData[i]}
          isInitiallyEditable={!childData[i]}
          onSubmit={onSubmit}
          onCancel={() => onCancel(i)}
          onDelete={childData[i] && (() => onDelete(childData[i].id))}
        >
          {({ editable }) => (
            <FormField
              label={`Child ${i + 1}`}
              editable={editable}
              customValue={values =>
                `${values.first_name} ${values.last_name} (${values.personal_id})`
              }
              fields={[
                {
                  name: 'id',
                  type: 'hidden',
                  value: childData[i] && childData[i].id,
                },
                {
                  name: 'first_name',
                  placeholder: 'First name',
                  validations: {
                    required: true,
                  },
                },
                {
                  name: 'last_name',
                  placeholder: 'Last name',
                  validations: {
                    required: true,
                  },
                },
                {
                  name: 'personal_id',
                  placeholder: 'Personal id',
                  validations: {
                    required: true,
                  },
                },
                {
                  name: 'date_of_birth',
                  placeholder: 'Date of birth (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
                {
                  name: 'nationality',
                  placeholder: 'Nationality',
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
          )}
        </FormControls>
      ))}
      <FormButton onClick={addNewChild}>Add information</FormButton>
    </FormBox>
  );
}

Children.propTypes = {
  title: string,
  childData: arrayOf(shape({})).isRequired,
  addChild: func.isRequired,
  updateChild: func.isRequired,
  deleteChild: func.isRequired,
};

Children.defaultProps = {
  title: 'Children',
};

export default Children;
