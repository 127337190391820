import React from 'react';
import { Heading } from '@chakra-ui/core';

export const H1 = ({ as, children, ...rest }) => (
  <Heading
    as={as}
    color="nude"
    fontSize="5xl"
    fontFamily="secondary"
    fontWeight="normal"
    {...rest}
  >
    {children}
  </Heading>
);
export const H2 = ({ as, children, ...rest }) => (
  <Heading
    as={as}
    color="nude"
    fontSize="4xl"
    fontFamily="secondary"
    fontWeight="normal"
    {...rest}
  >
    {children}
  </Heading>
);
export const H3 = ({ as, children, ...rest }) => (
  <Heading
    as={as}
    fontSize="3xl"
    fontFamily="secondary"
    fontWeight="normal"
    marginBottom="2.5rem"
    {...rest}
  >
    {children}
  </Heading>
);

export const H4 = ({ as, children, ...rest }) => (
  <Heading
    as={as}
    fontSize="2xl"
    fontFamily="secondary"
    fontWeight="normal"
    marginBottom="2.5rem"
    {...rest}
  >
    {children}
  </Heading>
);
