import React, { useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link, NavLink as RouterNavLink } from '../../util/router';
import { useAuth } from '../../util/auth';
import CLogo from '../../assets/images/connect-logo.svg';
// import { ReactComponent as NotificationIcon } from '../../assets/images/notification.svg';
// import inboxEmptyIcon from '../../assets/images/inbox-empty.svg';
import theme from '../../theme';

const navItemStyles = css`
  color: ${theme.colors.sky};
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0.5rem 0.75rem;
`;

const MenuButton = styled(Flex)`
  outline: none;
`.withComponent('button');

const NavLink = styled(RouterNavLink)`
  ${navItemStyles};
  padding: 0.5rem 1.875rem;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    padding: 1.5rem 1rem;
  }

  .circle {
    fill: ${theme.colors.white};
  }

  &.active {
    font-weight: bold;
  }
  &.user-menu {
    height: auto;
    padding: 0 1.25rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 0 1rem;
    }
  }
  &.profile-menu {
    background: ${theme.colors.space};
    height: auto;
    justify-content: center;
    min-width: 90px;
    padding: 1.5rem 1rem;
    position: absolute;
    top: 100%;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      right: 0;
    }

  }
  &.profile-menu-trigger {
    height: 100%;
  }
  &.notification {
    .circle {
      fill: ${theme.colors.notification};
    }
  }
`;
const NavItem = styled(Box)`
  ${navItemStyles};
  padding: 0;
`;

const StyledHamburger = styled.div`
  border-radius: 4px;
  background-color: #fff;
  display: none;
  position: relative;
  height: 3px;
  margin-left: 1.875rem;
  transition-property: transform;
  width: 30px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;

  @media only screen and (max-width: ${theme.breakpoints.md}) {
    display: block;
  }

  &.is-active {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);
  }

  &.is-active:before {
    top: 0;
    transition: top 75ms ease, opacity 75ms ease 0.12s;
    opacity: 0;
  }

  &.is-active:after {
    bottom: 0;
    transition: bottom 75ms ease,
      transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(-90deg);
  }

  &:after,
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
  }

  &:before {
    top: -10px;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }

  &:after {
    bottom: -10px;
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

function Navbar({ headerClass }) {
  const { isAuthenticated, logout, user } = useAuth();

  const [navVisible, setNavVisible] = useState(() => window.innerWidth > 767);
  const [userNavVisible, setUserNavVisible] = useState(false);
  // const [notification, setNotification] = useState(false); // TODO: do this apparently :cat_thinking:
  // const notification = false;

  const toggleMenu = () => {
    setNavVisible(prev => !prev);
  };

  return (
    <header className={headerClass}>
      <Box
        className="nav"
        as="nav"
        px="1rem"
        role="navigation"
        aria-label="main navigation"
        p="0"
      >
        <Flex
          className="container"
          align="stretch"
          justify="flex-start"
          w="100%"
          maxW="8xl"
          master
          flexGrow="1"
          m="0"
          position="relative"
          zIndex="10"
        >
          <Flex className="navbar-brand" align="stretch" flexShrink="0">
            <NavItem>
              <Link to="/">
                <Image src={CLogo} alt="Logo" />
              </Link>
            </NavItem>
          </Flex>

          {isAuthenticated && ['manager', 'sales'].includes(user.claims.role) && (
            <MenuButton alignItems="center" onClick={toggleMenu}>
              <StyledHamburger className={navVisible ? 'is-active' : ''} />
            </MenuButton>
          )}

          <Flex
            display={{ base: navVisible ? 'flex' : 'none', md: 'flex' }}
            pos={{ base: 'absolute', md: 'static' }}
            top={{ base: '110px', md: 'auto' }}
            left={{ base: '110px', md: 'auto' }}
            bg={{ base: 'space', md: 'transparent' }}
            alignItems={{ base: 'start', md: 'center' }}
            justifyContent={{ base: 'flex-start', md: 'flex-end' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {isAuthenticated && (
              <>
                {['manager', 'sales'].includes(user.claims.role) && (
                  <>
                    <NavLink to="/">Dashboard</NavLink>
                    <NavLink to="/clients">Clients</NavLink>
                  </>
                )}
                {['manager'].includes(user.claims.role) && (
                  <>
                    <NavLink to="/activity">Activity</NavLink>
                  </>
                )}
              </>
            )}
          </Flex>

          <Flex
            align="stretch"
            justify="flex-end"
            w="100%"
            marginRight={{ base: '0', md: '1rem' }}
          >
            {isAuthenticated && (
              <>
                {/* ['manager', 'sales'].includes(user.claims.role) && (
                  <NavLink to="/" className="user-menu">
                    <Image src={inboxEmptyIcon} />
                  </NavLink>
                )}

                {['manager', 'sales'].includes(user.claims.role) && (
                  <NavLink
                    to="/"
                    className={
                      notification ? 'notification user-menu' : 'user-menu'
                    }
                  >
                    <NotificationIcon />
                  </NavLink>
                ) */}

                <Flex flexDirection="column">
                  <NavLink
                    to="#"
                    className="profile-menu-trigger"
                    onClick={e => {
                      e.preventDefault();
                      setUserNavVisible(prev => !prev);
                    }}
                  >
                    <Image
                      src={user.picture}
                      w="40px"
                      h="40px"
                      borderRadius="50%"
                    />
                  </NavLink>

                  {userNavVisible && (
                    <NavLink
                      to="#"
                      className="profile-menu"
                      onClick={e => {
                        e.preventDefault();
                        logout();
                      }}
                    >
                      Sign out
                    </NavLink>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </header>
  );
}

export default Navbar;
