import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAuth } from '../../../util/auth';
import NotFound from '../../notfound';
import HomePage from '../../home';
import ClientsPage from '../../clients';
import ActivityPage from '../../activity';
import { Switch, Route, ProtectedRoute, Router } from '../../../util/router';
import ClientProfile from '../../../components/ClientProfile';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';

const GET_PERSON = gql`
  query getPerson($sub: String!) {
    persons(where: { auth0_sub: { _eq: $sub } }) {
      id
    }
  }
`;

function AppRoutes() {
  const { user } = useAuth();
  const client = user || {};

  const { data } = useQuery(GET_PERSON, {
    skip: !client.sub,
    variables: { sub: client.sub },
  });

  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" render={props => <HomePage {...props} />} />
          <Route
            path="/u"
            render={props => (
              <ClientProfile
                {...props}
                personId={data && data.persons[0] && data.persons[0].id}
              />
            )}
          />
          <ProtectedRoute
            path="/clients"
            component={ClientsPage}
            roles={['manager', 'sales']}
          />

          <ProtectedRoute
            path="/activity"
            component={ActivityPage}
            roles={['manager', 'sales']}
          />

          {/* Fallback to a 404 page if no route matches */}
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default AppRoutes;
