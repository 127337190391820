import React from 'react';
import { ThemeProvider } from '@chakra-ui/core';
import { AuthProvider } from '../../util/auth';
import ApolloProvider from '../../util/apollo';
import GoogleProvider from '../../util/googleTasks';
import { auth0Config } from '../../config';
import customTheme from '../../theme';
import GlobalStyles from '../../util/globalStyles';
import AppRoutes from './appRoutes';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <GlobalStyles />
      <AuthProvider
        domain={auth0Config.domain}
        client_id={auth0Config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={auth0Config.audience}
        scope={auth0Config.scope}
      >
        <ApolloProvider>
          <GoogleProvider>
            <AppRoutes />
          </GoogleProvider>
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
