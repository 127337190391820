import React from 'react';
import { oneOfType, bool, func, string, node } from 'prop-types';
import { Flex, Text } from '@chakra-ui/core';

function FormFieldRow({ lookEditable, name, label, children, ...rest }) {
  return (
    <Flex
      justify="flex-start"
      borderTop="1px solid lightgrey"
      borderColor="light-grey"
      p="1.25rem 1.25rem 0"
      bg={lookEditable ? 'sky' : 'white'}
      flexDirection={{ base: 'column', md: 'row' }}
      align={{ base: 'stretch', md: 'center' }}
      {...rest}
    >
      <Flex flexBasis="20%" align="stretch" minW="125px">
        <Text
          as="label"
          fontWeight="bold"
          htmlFor={name}
          marginBottom="1.25rem"
        >
          {label}
        </Text>
      </Flex>
      <Flex
        flexBasis="50%"
        flexDirection='column'
        justify="flex-start"
      >
        {children}
      </Flex>
    </Flex>
  );
}

FormFieldRow.propTypes = {
  lookEditable: bool,
  name: string,
  label: string.isRequired,
  children: oneOfType([func, node]).isRequired,
};

FormFieldRow.defaultProps = {
  lookEditable: false,
  name: '',
};
export default FormFieldRow;
