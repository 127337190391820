import React from 'react';
import { string, shape, node, number, bool } from 'prop-types';
import { Text, Box } from '@chakra-ui/core';
import moment from 'moment';
import { H2 } from '../Typography';

function ClientHeader({
  children,
  title,
  person,
  nameAsHeader,
  displayContactInfo,
  notes,
}) {
  let age = null;

  if (person && person.date_of_birth) {
    // check whats the date format
    const parts = person.date_of_birth.split(/[./-]/);
    let initialDate;
    if (parts[0].length > 2) {
      initialDate = moment(person.date_of_birth, 'YYYY-MM-DD');
    } else {
      initialDate = moment(person.date_of_birth, 'DD-MM-YYYY');
    }
    age = moment().diff(moment(initialDate).format('YYYY-MM-DD'), 'years');
  }

  return (
    <Box p={{ base: '6rem 1.5rem 0', sm: '6rem 2.5rem 0', xl: '6rem 1rem 0 7rem' }}>
      {nameAsHeader && person ? (
        <>
          <H2 as="h1" marginBottom="3rem">
            {person.first_name} {person.last_name}
          </H2>
          <Text marginBottom="0.5rem" color="nude" fontSize="xl">
            {age && <span>{age}</span>}
            {age && person.city && `, `}
            {person.city && <span>{person.city}</span>}
          </Text>
        </>
      ) : (
        <>
          <H2 as="h1" marginBottom="3rem">
            {title}
          </H2>
          {notes && <Text color="nude" mt="-3rem" mb="3rem">These notes are for Mercurius internal use only and won't be visible to the client</Text>}
          {person && (
            <Text marginBottom="0.5rem" color="nude" fontSize="xl">
              {person.first_name} {person.last_name}
              {age && <span>, {age}</span>}
              {person.city && <span>, {person.city}</span>}
            </Text>
          )}
        </>
      )}
      {displayContactInfo && person && (
        <Box marginBottom="2.25rem" color="sky">
          <Box
            as="a"
            href={`mailto:${person.email}`}
            d="inline-block"
            marginRight="10px"
          >
            {person.email}
          </Box>
          {person.phone_number && (
            <>
              <span>|</span> 
              <Box as="a" marginLeft="5px" href={`tel: ${person.phone_number}`}>
                {person.phone_number}
              </Box>
            </>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
}

ClientHeader.propTypes = {
  children: node,
  title: string,
  person: shape({
    id: number,
  }),
  displayContactInfo: bool,
  nameAsHeader: bool,
  notes: bool
};

ClientHeader.defaultProps = {
  children: null,
  title: null,
  person: null,
  nameAsHeader: false,
  displayContactInfo: true,
  notes: false
};

export default ClientHeader;
