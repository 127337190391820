import React from 'react';
import { Flex, Image } from '@chakra-ui/core';
import { H3 } from '../Typography';
import ContentOutput from '../ContentOutput';
import Mlogo from '../../assets/images/m-logo-white.svg';

function Footer() {
  return (
    <footer>
      <Flex>
        <Image src={Mlogo} />
      </Flex>
      <Flex
        marginTop="5rem"
        flexDirection={{ base: 'column', lg: 'row' }}
        paddingLeft={{ base: '0', lg: '130px' }}
      >
        <Flex
          flexBasis="50%"
          flexDirection="column"
          marginBottom="2.5rem"
          p={{ base: '0 0 2rem 0', lg: '0 3.75rem 0 0' }}
          borderBottom={{ base: '1px solid', lg: '0' }}
        >
          <H3 marginBottom="2rem">Questions?</H3>
          <ContentOutput marginBottom="1.25rem">
            If you have questions about Mercurius, policies or products, or your
            service, <br />
            <a href="mailto:sales@mercuriusassurance.com">
              Contact us via email
            </a>
          </ContentOutput>
        </Flex>
        <Flex
          flexBasis="50%"
          p={{ base: '0', lg: '0 0 0 5.5rem', xxl: '0 5.5rem' }}
          borderLeft={{ base: '0', lg: '1px solid' }}
          borderColor="sky"
          flexDirection="column"
        >
          <H3 marginBottom="2rem">Helpdesk</H3>
          <ContentOutput marginBottom="1.25rem">
            For issues with using Mercurius Connect digital platform, <br />
            <a href="mailto:support@mercuriusassurance.com">
              Contact our technical support here
            </a>
          </ContentOutput>
        </Flex>
      </Flex>
    </footer>
  );
}

export default Footer;
