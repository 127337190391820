import React from 'react';
import { Flex, Box, Icon } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled/macro';
//
import theme from '../../theme';
import { Link } from '../../util/router';
import { useAuth } from '../../util/auth';
import Section from '../Section';
import Container from '../Container';
import { H1, H3 } from '../Typography';
import ArrowSvg from '../../assets/images/arrow.svg';
import TaskList from '../TaskList';
import Loading from '../Loading';
import Error from '../Error';
import AuditLogRow from '../AuditLog/AuditLogRow';
import { GET_CLIENTS } from '../ClientList/queries';

const StyledImage = styled.img`
  transform: rotate(180deg);
`;

const ArrowIcon = imgProps => <StyledImage src={ArrowSvg} {...imgProps} />;

const ToolLink = styled(Link)`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1;
  display: flex;
  min-height: 40px;
`;

const ArrowedLink = styled(ToolLink)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${StyledImage} {
    transition: margin-right 0.125s ease-in;
  }

  &:hover,
  &:focus,
  &:active {
    ${StyledImage} {
      transition-timing-function: ease-out;
      margin-right: -0.25em;
    }
  }
`;

const TableContainer = styled(Box)`
  overflow-x: auto;
  width: 100%;
`;

const Username = styled.span`
  word-break: break-word;
`;

const SidebarBox = styled(Box)`
  padding: 2.5rem 2.5rem 3rem 2.5rem;

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    padding: calc(3rem + 1%) calc(3rem + 2%);
  }
`;

const GreetingBox = styled(SidebarBox)`
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    display: none;
  }
`;

const AUDIT_LOG_FIELDS = gql`
  fragment auditLogFields on audit_logged_actions {
    action
    table_name
    row_data
    changed_fields
    hasura_user
    action_tstamp_tx
  }
`;

const GET_AUDIT_LOG = gql`
  query getAuditLog {
    audit_logged_actions(order_by: { action_tstamp_tx: desc }, limit: 5) {
      ...auditLogFields
    }
  }
  ${AUDIT_LOG_FIELDS}
`;

function Dashboard() {
  const { user } = useAuth();

  const getFirstName = ({ name }) =>
    name
      .split(' ')
      .slice(0, 1)
      .join();

  const { error, loading, data } = useQuery(GET_AUDIT_LOG, {
    skip: !!['sales'].includes(user.claims.role),
  });

  const { data: clientsData } = useQuery(GET_CLIENTS, {
    variables: {
      order_by: {
        updated_at: 'asc',
      },
      limit: 5,
      offset: 0,
      search: '',
      where: {
        _and: {
          parent_id: { _is_null: true },
          grandparent_id: { _is_null: true },
          spouse_id: { _is_null: true },
        },
      },
    },
  });

  const renderPolicy = (policies, type) => {
    return (
      policies.filter(policy => policy.type === type).length !== 0 && (
        <Icon name="check" size="1.5rem" color="light-grey" />
      )
    );
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  // TODO: Fix clickarea by getting history.push from somewhere and bind onClick to <tr> and then remove Link

  return (
    <Section>
      <Container seamless>
        <Flex flexDirection={{ base: 'column', xl: 'row' }}>
          <Flex direction="column" mb="2.5rem">
            {['manager'].includes(user.claims.role) && (
              <Box p="0 2.5rem 3rem 2.5rem" flexBasis="60%" bg="white">
                <ArrowedLink to="/activity">
                  <H3 as="h2" mt="2.5rem">
                    Activity
                  </H3>
                  <ArrowIcon />
                </ArrowedLink>
                {data.audit_logged_actions.length !== 0 ? (
                  data.audit_logged_actions.map((log, i) => (
                    <AuditLogRow key={i} log={log} user={user} />
                  ))
                ) : (
                  <Box>There's no new activity</Box>
                )}
                {data.audit_logged_actions.length !== 0 && (
                  <Flex justifyContent="center" p="1.5rem 0 0">
                    <ToolLink to="/activity">See all</ToolLink>
                  </Flex>
                )}
              </Box>
            )}
            <Box backgroundColor="sky" flexBasis="40%">
              <Box mx="2.5rem">
                <ArrowedLink to="/clients">
                  <H3 as="h2" mt="2.5rem">
                    Clients
                  </H3>
                  <ArrowIcon />
                </ArrowedLink>
              </Box>
              <TableContainer>
                <table>
                  <thead>
                    <tr>
                      <Box as="th">Name</Box>
                      <Box as="th">City</Box>
                      <Box as="th">Phone</Box>
                      <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                        Life
                      </Box>
                      <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                        Risk
                      </Box>
                      <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                        Lifestyle
                      </Box>
                      {/* <Box as="th">Priority</Box> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!!clientsData &&
                      clientsData.persons &&
                      clientsData.persons.map(person => (
                        <tr key={person.id}>
                          <Box as="td" className="name">
                            <Link to={`clients/${person.id}`}>
                              {person.last_name}, {person.first_name}
                            </Link>
                          </Box>
                          <Box as="td">{person.city}</Box>
                          <Box as="td">{person.phone_number}</Box>
                          <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                            {person.policies &&
                              renderPolicy(person.policies, 'life')}
                          </Box>
                          <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                            {person.policies &&
                              renderPolicy(person.policies, 'risk')}
                          </Box>
                          <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                            {person.policies &&
                              renderPolicy(person.policies, 'lifestyle')}
                          </Box>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TableContainer>
            </Box>
          </Flex>

          <Box flexBasis="40%">
            <GreetingBox>
              <H1>Hi {user && <Username>{getFirstName(user)}</Username>}</H1>
            </GreetingBox>
            <SidebarBox bg="space">
              <TaskList />
            </SidebarBox>
            <SidebarBox backgroundColor="sky">
              <H3>Tools</H3>
              <ArrowedLink to="/clients/new">
                Add a new client
                <ArrowIcon />
              </ArrowedLink>
            </SidebarBox>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
}

export default Dashboard;
