import React from 'react';
import { ListItem, Text, Checkbox } from '@chakra-ui/core';
import { shape, func, arrayOf } from 'prop-types';

import { formatDate } from '../../util/helpers';

const Task = ({ task, handleChange, checkedItems, today }) => {
  return (
    <ListItem>
      <Checkbox
        isChecked={checkedItems[task.id] || task.status === 'completed'}
        onChange={e => handleChange(e, task.id)}
        my={2}
        name={task.id}
      >
        <Text
          color={
            checkedItems[task.id] || task.status === 'completed'
              ? 'gray'
              : task.due && formatDate(Date.parse(task.due) <= today)
              ? 'danger'
              : 'white'
          }
          textDecoration={
            checkedItems[task.id] || task.status === 'completed'
              ? 'line-through'
              : 'none'
          }
          fontSize="sm"
          mx={1}
        >
          {task.title}
        </Text>
      </Checkbox>
    </ListItem>
  );
};

Task.defaultProps = {
  task: {},
  checkedItems: [],
};

Task.propTypes = {
  task: shape({}),
  checkedItems: arrayOf(shape({})),
  handleChange: func.isRequired,
};

export default Task;
