import React from 'react';
import { string } from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../util/auth';
import PersonalData from './PersonalData';
import SpouseData from './SpouseData';
import Children from './Children';
import Loading from '../Loading';
import Error from '../Error';
import ClientHeader from '../ClientHeader';
import {
  GET_PERSON,
  ADD_PERSON,
  UPDATE_PERSON,
  DELETE_PERSON,
} from './queries';

function ClientPersonalInfo({ clientId }) {
  const { user } = useAuth();
  const isNotClient = ['manager', 'sales'].includes(user.claims.role);

  const { error, loading, data } = useQuery(GET_PERSON, {
    variables: { personId: clientId, isNotClient },
  });

  const [updatePerson] = useMutation(UPDATE_PERSON, {
    refetchQueries: [
      {
        query: GET_PERSON,
        variables: {
          personId: clientId,
          isNotClient
        },
      },
    ],
  });
  const [addPerson] = useMutation(ADD_PERSON, {
    refetchQueries: [
      {
        query: GET_PERSON,
        variables: {
          personId: clientId,
          isNotClient
        },
      },
    ],
  });
  const [deletePerson] = useMutation(DELETE_PERSON, {
    refetchQueries: [
      {
        query: GET_PERSON,
        variables: {
          personId: clientId,
          isNotClient
        },
      },
    ],
  });

  const addChild = (childData, { withexspouse, grandchild }) =>
    addPerson({
      variables: {
        formValues: {
          ...childData,
          parent_id: grandchild ? null : clientId,
          grandparent_id: grandchild ? clientId : null,
          withexspouse,
        },
      },
    });
  const addSpouseChild = (parentId, childData) =>
    addPerson({
      variables: {
        formValues: {
          ...childData,
          parent_id: parentId,
        },
      },
    });
  const updateChild = (id, childData) =>
    updatePerson({
      variables: {
        personId: id,
        formValues: childData,
      },
    });
  const deleteChild = childId =>
    deletePerson({
      variables: {
        personId: childId,
      },
    });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <>
      <ClientHeader title="Personal info" person={data.person} />
      <PersonalData updatePerson={updatePerson} person={data.person} />
      <SpouseData
        title="Spouse data"
        updatePerson={updatePerson}
        addPerson={addPerson}
        person={data.person}
      />
      <Children
        title="Common children"
        updateChild={updateChild}
        addChild={childData => addChild(childData, {})}
        deleteChild={deleteChild}
        childData={data.person.children.filter(child => !child.withexspouse)}
      />
      <Children
        title="Children from previous relationships"
        updateChild={updateChild}
        addChild={childData => addChild(childData, { withexspouse: true })}
        deleteChild={deleteChild}
        childData={data.person.children.filter(child => !!child.withexspouse)}
      />
      {data.person.spouse && (
        <Children
          title="Spouse's children from previous relationships"
          updateChild={updateChild}
          addChild={childData =>
            addSpouseChild(data.person.spouse.id, childData)
          }
          deleteChild={deleteChild}
          childData={data.person.spouse.children}
        />
      )}
      <Children
        title="Grandchildren"
        updateChild={updateChild}
        addChild={childData => addChild(childData, { grandchild: true })}
        deleteChild={deleteChild}
        childData={data.person.grandchildren}
      />
    </>
  );
}

ClientPersonalInfo.propTypes = {
  clientId: string.isRequired,
};

export default ClientPersonalInfo;
