import React from 'react';
import { arrayOf, shape, number, string, func } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';

function WealthForm({ heading, description, items, onSave }) {
  // modify the array of items into an object to give to react-form
  const initialValues = items.reduce((values, item) => {
    return {
      ...values,
      [item.name.toLowerCase()]: {
        id: item.id,
        amount: item.amount,
        currency: item.currency,
      },
    };
  }, {});

  const onSubmit = newItems => {
    // modify the form value object to an array of items before passing on
    const values = Object.keys(newItems).map(name => ({
      name,
      ...newItems[name],
    }));

    // remove unchanged data from payload (to clean audit log)
    const newValues = [];
    items.forEach(function(item, i) {
      if (values[i].amount !== item.amount) {
        newValues.push(values[i]);
      }
    });

    return onSave(newValues);
  };

  return (
    <FormBox heading={heading} description={description}>
      <FormControls initialValues={initialValues} onSubmit={onSubmit}>
        {({ editable }) => (
          <>
            {items.map(item => (
              <FormField
                key={item.name}
                label={item.label}
                editable={editable}
                fields={[
                  {
                    name: `${item.name}.amount`,
                    type: 'monetary',
                    currency: item.currency,
                    placeholder: item.placeholder,
                    validations: {
                      min: 0,
                    },
                  },
                  {
                    name: `${item.name}.id`,
                    type: 'hidden',
                    // TODO: remove value prop.
                    // Now adding value here because form defaultValues wealth.id is not updated when adding new wealth.
                    value: `${item.id}`,
                  },
                ]}
              />
            ))}
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

WealthForm.propTypes = {
  heading: string.isRequired,
  description: string,
  items: arrayOf(
    shape({
      id: number,
      type: string,
      amount: number,
      currency: string,
      name: string,
    }),
  ).isRequired,
  onSave: func.isRequired,
};

export default WealthForm;
