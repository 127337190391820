import React from 'react';
import { number } from 'prop-types';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { Box, Text } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';

import Loading from '../../Loading';
import Error from '../../Error';
import theme from '../../../theme';
import { GET_POLICY_COVERAGE } from '../queries';

const colors = [
  `${theme.colors.peach2}`,
  `${theme.colors.darkpurple}`,
  `${theme.colors.blue}`,
  `${theme.colors.green}`,
  `${theme.colors.purple}`,
  `${theme.colors.pink}`,
  `${theme.colors.red}`,
];

function MyCoverage({ clientId }) {
  const { error, loading, data } = useQuery(GET_POLICY_COVERAGE, {
    variables: { clientId },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  var chartData = [
    {
      name: 'empty',
      value: 1,
    },
  ];

  var wealthTotal = 0;

  const typeCoverage = data.get_policy_coverage.reduce(
    (acc, item) => ({
      ...acc,
      [item.type]: (acc[item.type] || 0) + item.total,
    }),
    {},
  );

  if (Object.keys(typeCoverage).length !== 0) {
    chartData = Object.keys(typeCoverage).map(type => ({
      name: type,
      value: typeCoverage[type],
    }));

    wealthTotal = Object.values(typeCoverage).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  }

  const RADIAN = Math.PI / 180;
  var renderCustomizedLabel;
  var chartPaddingAngle = 0;

  if (chartData[0].name !== 'empty') {
    chartPaddingAngle = 5;

    renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 3.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="#EEF0F4"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize="20px"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  }

  const renderColorfulLegendText = value => {
    value = value.replace('_', ' ');
    return (
      <span style={{ marginLeft: '6px', marginRight: '10px' }}>
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </span>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" minHeight="500px" maxHeight="550px">
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            innerRadius={140}
            outerRadius={150}
            fill="#EEF0F4"
            paddingAngle={chartPaddingAngle}
            label={renderCustomizedLabel}
            labelLine={false}
            stroke="none"
          >
            {chartData[0].name !== 'empty' &&
              chartData.map((entry, index) => (
                <Cell key={index} fill={colors[index % colors.length]} />
              ))}
          </Pie>
          {chartData[0].name !== 'empty' && (
            <Legend
              formatter={renderColorfulLegendText}
              layout="horizontal"
              align="center"
              verticalAlign="bottom"
              iconType="circle"
              iconSize={10}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
      <Text color="nude" pos="absolute" left={{ base: "1rem", md: "50px" }} top={{ base: "1.25rem", md: "40px" }}>
        Your coverage profile
      </Text>
      <Box
        pos="absolute"
        top="41%"
        fontFamily="secondary"
        fontSize="3xl"
        lineHeight="3.75rem"
      >
        {wealthTotal} €
      </Box>
    </>
  );
}

MyCoverage.propTypes = {
  clientId: number.isRequired,
};

export default MyCoverage;
