import React, { useState } from 'react';
import { string, shape, func } from 'prop-types';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import FormText from '../../Form/FormText';
import FormButton from '../../Form/FormButton';

function NoCredentials({ person, onSubmit }) {
  const [formOpen, setFormOpen] = useState(false);

  return (
    <>
      {!formOpen && (
        <>
          <FormText>
            The client has no credentials and cannot login to Mercurius Connect.
          </FormText>
          <FormButton
            type="button"
            variant="link"
            onClick={e => {
              e.preventDefault();
              setFormOpen(true);
            }}
          >
            Add login credentials
          </FormButton>
        </>
      )}

      {formOpen && (
        <FormControls
          initialValues={{
            email: person.email,
          }}
          onSubmit={onSubmit}
          isInitiallyEditable
        >
          {({ editable }) => (
            <>
              <FormField
                editable={false}
                label="Username"
                fields={[
                  {
                    name: 'email',
                  },
                ]}
              />
              {editable && (
                <>
                  <FormField
                    label="Password"
                    editable={editable}
                    fields={[
                      {
                        name: 'password',
                        placeholder: 'Enter password',
                        type: 'password',
                        validations: {
                          required: true,
                        },
                      },
                    ]}
                  />
                  <FormField
                    label="Repeat password"
                    editable={editable}
                    fields={[
                      {
                        name: 'repeatPassword',
                        placeholder: 'Enter password again',
                        type: 'password',
                        validations: {
                          required: true,
                        },
                      },
                    ]}
                  />
                </>
              )}
            </>
          )}
        </FormControls>
      )}
    </>
  );
}

NoCredentials.propTypes = {
  person: shape({
    email: string.isRequired,
  }).isRequired,
  onSubmit: func.isRequired,
};

export default NoCredentials;
