import React, { useState, useEffect } from 'react';
import { arrayOf, shape, bool } from 'prop-types';
import { Button } from '@chakra-ui/core';
import FormField from '../../Form/FormField';
import FormFieldRow from '../../Form/FormFieldRow'

function Insured({insured, editable}) {
  const [insuredCount, setInsuredCount] = useState(insured.length === 0 ? insured.push({}) : insured.length);

  useEffect(() => {
    setInsuredCount(insured.length);
  }, [insured]); // eslint-disable-line

  const addNewInsured = () => {
    setInsuredCount(prev => prev + 1);
  };

  return (
    <>
      <FormField
        label="Insured"
        editable={editable}
        customValue={values =>
          {
            const output = Object.values(values).filter((value) => isNaN(value))
            return output.join(', ');
          }
        }

        fields={Array.from({ length: insuredCount }, (_, i) => i).reduce((values, i) => {
          return [
            ...values,
            {
              key: `${(insured[i] || {}).id || `new_${i}`}_name`,
              name: `insured[${i}].name`,
              placeholder: 'Name of description of insured',
            },
            {
              key: `${(insured[i] || {}).id || `new_${i}`}_id`,
              name: `insured[${i}].id`,
              type: 'hidden',
              value: insured[i] && insured[i].id
            },
          ]
        }, [])}
      />
      {editable && (
        <FormFieldRow borderTop="0" label="" lookEditable={editable}>
          <Button
            mb="1rem"
            rounded="0"
            bg="grey"
            type="button"
            maxWidth="110px"
            onClick={addNewInsured}
            fontWeight="bold"
            variant="ghost"
            alignSelf="flex-end"
          >
            Add +
          </Button>
        </FormFieldRow>
      )}
    </>
  );
}

Insured.propTypes = {
  insured: arrayOf(shape({})),
  editable: bool.isRequired,
};

Insured.defaultProps = {
  insured: [{}],
};

export default Insured;
