import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

function Section({ children, ...rest }) {
  return (
    <PseudoBox as="section" p="0 0 3rem" {...rest}>
      {children}
    </PseudoBox>
  );
}

export default Section;
