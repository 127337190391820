import React from 'react';
import { string } from 'prop-types';
import { Text, Flex } from '@chakra-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useAuth } from '../../util/auth';
import Loading from '../Loading';
import Error from '../Error';
import NoteForm from './NoteForm';
import NoteWrapper from './NoteWrapper';
import ClientHeader from '../ClientHeader';
import {
  GET_PERSON_NOTES,
  ADD_PERSON_NOTE,
  UPDATE_PERSON_NOTE,
  DELETE_PERSON_NOTE,
} from './queries';

function ClientNotes({ clientId }) {
  const { user } = useAuth();

  const { error, loading, data } = useQuery(GET_PERSON_NOTES, {
    variables: { personId: clientId },
  });

  const [updatePersonNote] = useMutation(UPDATE_PERSON_NOTE, {
    refetchQueries: [
      {
        query: GET_PERSON_NOTES,
        variables: {
          personId: clientId,
        },
      },
    ],
  });

  const [addPersonNote] = useMutation(ADD_PERSON_NOTE, {
    refetchQueries: [
      {
        query: GET_PERSON_NOTES,
        variables: {
          personId: clientId,
        },
      },
    ],
  });

  const [deletePersonNote] = useMutation(DELETE_PERSON_NOTE, {
    refetchQueries: [
      {
        query: GET_PERSON_NOTES,
        variables: {
          personId: clientId,
        },
      },
    ],
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const onSubmit = formValues => {
    if (formValues.id) {
      return updatePersonNote({
        variables: {
          noteId: formValues.id,
          formValues,
        },
      });
    }

    return addPersonNote({
      variables: {
        formValues,
      },
    });
  };

  const onDelete = formValues => {
    return deletePersonNote({
      variables: {
        id: formValues.id,
      },
    });
  };

  return (
    <>
      <ClientHeader title="Notes" person={data.persons[0]} notes />
      <Flex bg="white" flexDirection="column" p="2.5rem">
        <Flex bg="white" w="100%" p="0" color="space" flexDirection="column">
          <NoteForm clientId={clientId} user={user} onSave={onSubmit} />
        </Flex>
        {data.person_notes.length !== 0 ? (
          data.person_notes.map(note => (
            <NoteWrapper
              key={note.id}
              note={note}
              clientId={clientId}
              user={user}
              onSave={onSubmit}
              onDelete={onDelete}
            />
          ))
        ) : (
          <Text marginTop="2.5rem">There are no notes</Text>
        )}
      </Flex>
    </>
  );
}

ClientNotes.propTypes = {
  clientId: string.isRequired,
};

export default ClientNotes;
