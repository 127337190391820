import React from 'react';
import { string, shape, func } from 'prop-types';
import moment from 'moment';
import { Button } from '@chakra-ui/core';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';
import FormFieldRow from '../../Form/FormFieldRow';

function HasCredentials({ person, auth0User, onRevoke, loading }) {
  return (
    <FormControls
      initialValues={auth0User}
      isInitiallyEditable={false}
      onSubmit={onRevoke}
      hideButtons={['save']}
    >
      {({ editable, submitForm }) => (
        <>
          <FormField
            editable={false}
            lookEditable={editable}
            label="Username"
            fields={[
              {
                name: 'email',
              },
            ]}
          />
          <FormField
            editable={false}
            lookEditable={editable}
            label="Credentials created"
            customValue={({ created_at: createdAt }) =>
              moment(createdAt).format('LLL')
            }
            fields={[
              {
                name: 'created_at',
              },
            ]}
          />
          <FormField
            label="Account verified"
            editable={false}
            lookEditable={editable}
            customValue={({ email_verified: verified }) =>
              verified === 'true' ? 'Yes' : 'No'
            }
            fields={[
              {
                name: 'email_verified',
              },
            ]}
          />
          <FormField
            label="Last login"
            editable={false}
            lookEditable={editable}
            customValue={({ last_login: lastLogin }) =>
              lastLogin ? moment(lastLogin).format('LLL') : 'Never'
            }
            fields={[
              {
                name: 'last_login',
              },
            ]}
          />

          {editable && (
            <FormFieldRow label="" lookEditable={editable}>
              <Button
                ml="1rem"
                bg="danger"
                type="button"
                maxWidth="180px"
                color="white"
                onClick={submitForm}
                isLoading={loading}
                loadingText="Revoking"
              >
                Revoke credentials
              </Button>
            </FormFieldRow>
          )}
        </>
      )}
    </FormControls>
  );
}

HasCredentials.propTypes = {
  person: shape({
    email: string.isRequired,
  }).isRequired,
  onRevoke: func.isRequired,
};

export default HasCredentials;
