import { theme } from '@chakra-ui/core';
import Background from './assets/images/header-space.jpg';
import ChartBg from './assets/images/chartbg.jpg';
import footerBg from './assets/images/footer-space.png';

// First, create an alias for breakpoints
const breakpoints = ['30em', '48em', '62em', '80em', '97.5em', '120em'];
// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];
breakpoints.xxxl = breakpoints[5];

const customTheme = {
  ...theme,
  breakpoints,
  background: {
    header: Background,
    footer: footerBg,
    chartBg: ChartBg,
  },
  fonts: {
    primary: '"Lato", sans-serif',
    secondary: '"Noe display", sans-serif',
  },
  fontSizes: {
    xs: '0.75rem', // 12
    sm: '0.875rem', // 14
    md: '1rem',
    lg: '1.125rem', // 18
    xl: '1.25rem', // 20
    '2xl': '1.625rem', // 26
    '3xl': '2.5rem', // 40
    '4xl': '3.25rem', // 52
    '5xl': '4.5rem', // 72
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: 'normal',
    shorter: '1.25',
    short: '1.3',
    medium: '1.4',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  colors: {
    ...theme.colors,
    white: '#FFF',
    'light-grey': '#D8D8D8',
    space: '#181C2E',
    sky: '#EEF0F4',
    peach: '#fecfc3',
    mauve: '#ae8fb1',
    night: '#889bd3',
    teal: '#2a7c97',
    nude: '#ffede8',
    moon: '#e5ddee',
    ice: '#cddefa',
    mint: '#bde6f0',
    primary: '#181c2e',
    info: '#889bd3',
    success: '#181c2e',
    warning: '#ff9861',
    danger: '#962c4e',
    active: '#3FB52C',
    gray: '#CBCED9',
    notification: '#DE3F72',
    peach2: '#FFB1B1',
    darkpurple: '#7B4680',
    blue: '#8CACFF',
    green: '#7CDEE4',
    purple: '#6448E9',
    pink: '#E1A9F5',
    red: '#FE5E78'
  },
  sizes: {
    ...theme.sizes,
    '7xl': '90rem',
    '8xl': '120rem',
  },
};

export default customTheme;
