import gql from 'graphql-tag';

export const GET_CLIENTS = gql`
  query getPersons(
    $order_by: [persons_order_by!]
    $search: String!
    $where: persons_bool_exp
    $limit: Int!
    $offset: Int!
  ) {
    persons: search_persons(
      where: $where
      args: { search: $search }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      first_name
      last_name
      marital_status
      nationality
      city
      auth0_sub
      parent_id
      personal_id
      phone_number
      postal_code
      spouse_id
      street_address
      taxation_country
      withexspouse
      email
      notes(order_by: { updated_at: desc }) {
        id
        person_id
        content
        created_at
        updated_at
      }
      policies {
        type
        status
      }
    }
    total: persons_aggregate(
      where: {
        _and: {
          parent_id: { _is_null: true }
          grandparent_id: { _is_null: true }
          spouse_id: { _is_null: true }
          deleted: { _neq: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CITIES = gql`
  query {
    persons(
      distinct_on: [city]
      order_by: { city: asc }
      where: {
        _and: {
          parent_id: { _is_null: true }
          grandparent_id: { _is_null: true }
          spouse_id: { _is_null: true }
        }
      }
    ) {
      id
      city
    }
  }
`;
