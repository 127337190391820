import React, { useState, useEffect } from 'react';
import { string, array } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';

function SelectField({ ...props }) {
  const { register, setValue } = useFormContext();
  const { name, initialValue, options } = props;
  const [selectValue, setReactSelectValue] = useState({ selectedOption: [] });

  const handleChange = selectedOption => {
    setValue(name, selectedOption.value);
    setReactSelectValue({ selectedOption });
  };

  useEffect(() => {
    register({ name }); // custom register for react-select

    let initialSelect = {};
    options &&
      options.forEach(function(option, i) {
        if (option.value === initialValue) {
          initialSelect = option;
        }
      });

    setReactSelectValue({ selectedOption: [initialSelect] });
  }, [register, name, options, initialValue]);

  return (
    <Select
      name={name}
      options={options && options}
      onChange={handleChange}
      value={selectValue.selectedOption}
      {...props}
    />
  );
}

SelectField.propTypes = {
  name: string.isRequired,
  initialValue: string,
  options: array,
};

export default SelectField;
