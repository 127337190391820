import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_AUTH0_ROLES = gql`
  query getAuth0Roles {
    auth0_roles {
      name
      id
    }
  }
`;

export const GET_AUTH0_USER = gql`
  query getAuth0User($userSub: String!) {
    auth0_users(where: { id: { _eq: $userSub } }) {
      user_id
      name
      picture
      email
      email_verified
      last_login
      logins_count
      created_at
      user_metadata {
        phone_number
      }
    }
  }
`;

export const GET_AUTH0_USERS_BY_ROLE = gql`
  query getAuth0UsersByRole($roleIds: [String!]) {
    auth0_users(where: { role_id: { _in: $roleIds } }) {
      user_id
      name
    }
  }
`;

export const CREATE_AUTH0_USER = gql`
  mutation insertAuth0User($objects: [insert_auth0_users_input!]!) {
    insert_auth0_users(objects: $objects) {
      affected_rows
      returning {
        user_id
        email
        name
      }
    }
  }
`;

export const DELETE_AUTH0_USER = gql`
  mutation deleteAuth0User($userSub: String!) {
    delete_auth0_users(where: { id: { _eq: $userSub } }) {
      affected_rows
      returning {
        user_id
      }
    }
  }
`;

export const useAuth0Roles = () => {
  const { data: roleData } = useQuery(GET_AUTH0_ROLES);

  return (roleData && roleData.auth0_roles) || [];
};

export const useAuth0User = sub => {
  const { data } = useQuery(GET_AUTH0_USER, {
    skip: !sub,
    variables: { userSub: sub },
  });

  return (
    data && data.auth0_users && data.auth0_users.length && data.auth0_users[0]
  );
};

export const useAuth0UsersByRole = roleIds => {
  const { data: usersData } = useQuery(GET_AUTH0_USERS_BY_ROLE, {
    skip: !roleIds,
    variables: { roleIds },
  });

  return usersData && usersData.auth0_users;
};
