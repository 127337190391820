import React from 'react';
import { CSSReset } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';

function GlobalStyles() {
  const makeCss = theme => css`
    html {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      background: ${theme.colors.sky};
      color: ${theme.colors.space};
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      min-width: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      text-rendering: optimizeLegibility;
      text-size-adjust: 100%;
    }

    body {
      color: ${theme.colors.space};
      background: ${theme.colors.sky};
      background-image: url(${theme.background.header});
      background-repeat: repeat-x;
      background-position: 0 0;
      background-size: 100% 500px;

      &.dashboard {
        @media screen and (min-width: ${theme.breakpoints.md}) {
          background-size: 100% 700px;
        }
      }
    }

    .home-section {
      @media screen and (max-width: ${theme.breakpoints.md}) {
        background-image: url(${theme.background.header});
        background-repeat: repeat-x;
        background-position: 0 0;
        background-size: cover;
      }
    }

    header {
      background: transparent;
      height: 110px;
    }

    footer {
      background: transparent;
      background-image: url(${theme.background.footer});
      background-size: cover;
      background-position: 50% 0;
      color: ${theme.colors.sky};
      margin-top: -11px; // Covers the curve of bg
      padding: 160px 2.5rem;

      @media screen and (min-width: ${theme.breakpoints.sm}) {
        padding: 160px 90px;
      }
    }

    select {
      background-color: transparent !important;
    }

    .select-field {
      margin-bottom: 1rem;

      [class*='control'] {
        border: 0;
        border-radius: 0;
        min-height: 2.5rem;
        padding-left: 7px;
      }
    }

    .content-output {
      max-width: 700px;
      a {
        font-weight: 700;
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0 5px;
      text-align: left;
      width: 100%;

      tbody {
        tr {
          background: ${theme.colors.white};
        }
      }

      tr {
        td {
          padding: 25px 40px;

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            padding: 25px 20px;
          }
        }

        th {
          font-weight: normal;
          padding: 20px 40px;

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            padding: 25px 20px;
          }
        }

        .name {
          font-weight: bold;
        }
      }
    }

    .overview-chart {
      background-image: url(${theme.background.chartBg});
      background-size: cover;
      background-position: 0 0;
    }

    .m-button {
      color: ${theme.colors.white};
      width: 175px;
      height: 54px !important;
      border: 0 solid ${theme.colors.space};
      border-radius: 20% 20% 20% 20% / 300% 300% 300% 300% !important;
      background-color: ${theme.colors.space} !important;
      text-transform: uppercase;
    }

    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }

    .fa-spinner {
      animation: spinner 0.6s linear infinite;
    }
  `;

  return (
    <>
      <CSSReset />
      <Global styles={theme => makeCss(theme)} />
    </>
  );
}

export default GlobalStyles;
