import React from 'react';
import { shape, func, number } from 'prop-types';
import { useAuth } from '../../../util/auth';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';

function PersonalData({ person, updatePerson }) {
  const { user } = useAuth();

  const onSubmit = formValues => {
    return updatePerson({
      variables: {
        personId: person.id,
        formValues: {
          ...formValues,
          personal_id: formValues.personal_id || null,
        },
      },
    });
  };

  return (
    <FormBox heading="Personal data">
      <FormControls initialValues={person} onSubmit={onSubmit}>
        {({ editable }) => (
          <>
            <FormField
              label="Email"
              editable={editable}
              fields={[
                {
                  name: 'email',
                  placeholder: 'Email',
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
            <FormField
              label="First name"
              editable={editable}
              fields={[
                {
                  name: 'first_name',
                  placeholder: 'First name',
                  validations: {
                    required: true,
                  },
                },
              ]}
            />
            <FormField
              label="Last name"
              editable={editable}
              fields={[
                {
                  name: 'last_name',
                  placeholder: 'Last name',
                  value: `${person.last_name}`,
                },
              ]}
            />
            <FormField
              label="Personal id"
              editable={editable}
              fields={[
                {
                  name: 'personal_id',
                  placeholder: 'Personal id',
                  value: `${person.personal_id}`,
                },
              ]}
            />
            <FormField
              label="Date of birth"
              editable={editable}
              fields={[
                {
                  name: 'date_of_birth',
                  placeholder: 'Date of birth (DD-MM-YYYY)',
                  type: 'date',
                  // validation for DD MM YYYY, dividers: (accepts . / -)
                  validations: {
                    message: 'Please enter the date in this format: DD-MM-YYYY',
                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  },
                },
              ]}
            />
            <FormField
              label="Nationality"
              editable={editable}
              fields={[
                {
                  name: 'nationality',
                  placeholder: 'Nationality',
                  value: `${person.nationality}`,
                },
              ]}
            />
            <FormField
              label="Taxation country"
              editable={editable}
              fields={[
                {
                  name: 'taxation_country',
                  placeholder: 'Taxation country',
                  value: `${person.taxation_country}`,
                },
              ]}
            />
            <FormField
              label="Address"
              editable={editable}
              customValue={() => (
                <>
                  {person.street_address}
                  <br />
                  {person.postal_code}
                  <br />
                  {person.city}
                </>
              )}
              fields={[
                {
                  name: 'street_address',
                  placeholder: 'Street address',
                  value: `${person.street_address}`,
                },
                {
                  name: 'postal_code',
                  placeholder: 'Postal code',
                  value: `${person.postal_code}`,
                },
                {
                  name: 'city',
                  placeholder: 'City',
                  value: `${person.city}`,
                },
              ]}
            />
            <FormField
              label="Phone number"
              editable={editable}
              fields={[
                {
                  name: 'phone_number',
                  placeholder: 'Phone number',
                  value: `${person.phone_number}`,
                },
              ]}
            />
            <FormField
              label="Marital status"
              editable={editable}
              fields={[
                {
                  name: 'marital_status',
                  placeholder: 'Marital status',
                  value: `${person.marital_status}`,
                },
              ]}
            />
            {['manager', 'sales'].includes(user.claims.role) && (
              <FormField
                label="Proof of identification received"
                editable={editable}
                customValue={() => (
                  <>
                    {person.identification_received ? `Yes, ${person.identification_type}` : 'No'}
                  </>
                )}
                fields={[
                  {
                    name: 'identification_received',
                    placeholder: 'Select...',
                    type: 'select',
                    options: [
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ],
                  },
                  {
                    name: 'identification_type',
                    placeholder: 'Identification type',
                    value: `${person.id_type}`,
                  },
                ]}
              />
            )}
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

PersonalData.propTypes = {
  person: shape({
    id: number,
  }).isRequired,
  updatePerson: func.isRequired,
};

export default PersonalData;
