import React from 'react';
import { number } from 'prop-types';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { Text, Box, Flex } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAuth } from '../../util/auth';
import Loading from '../Loading';
import Error from '../Error';
import theme from '../../theme';
import ClientHeader from '../ClientHeader';
import { useAuth0User } from '../../hooks/Auth0';
import LatestNote from '../ClientNotes/LatestNote';
import MyCoverage from '../ClientPolicies/MyCoverage';
import MyPoliciesList from '../ClientPolicies/MyPoliciesList';

const WEALTH_FIELDS = gql`
  fragment wealthFields on person_wealth {
    id
    person_id
    type
    amount
    currency
    name
  }
`;

const GET_PERSON_OVERVIEW = gql`
  query getPersonOverview($personId: Int!) {
    person_wealth(where: { person_id: { _eq: $personId } }) {
      ...wealthFields
    }
    wealth_types {
      name
    }
    currencies {
      name
    }
    persons(where: { id: { _eq: $personId } }) {
      first_name
      last_name
      city
      email
      phone_number
      date_of_birth
      accountable_id
      backup_person_id
    }
    person_policies(
      where: { 
        type: { _eq: "ulip" },
        person_id: { _eq: $personId },
      }
    ) {
      id
      original_investment_amount
      most_recent_value
    }
  }
  ${WEALTH_FIELDS}
`;

const colors = [
  `${theme.colors.peach2}`,
  `${theme.colors.darkpurple}`,
  `${theme.colors.blue}`,
  `${theme.colors.green}`,
  `${theme.colors.purple}`,
  `${theme.colors.pink}`,
  `${theme.colors.red}`,
];

function ClientOverview({ match, clientId }) {
  const { user } = useAuth();
  const { error, loading, data } = useQuery(GET_PERSON_OVERVIEW, {
    variables: { personId: clientId },
  });

  const userData = useAuth0User(
    data && data.persons && data.persons[0].accountable_id,
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  // placeholder value before creating calendar
  // const reminders = false;

  let chartData = [
    {
      name: 'empty',
      value: 1,
    },
  ];

  let wealthTotal = 0;

  const wealthSums = data.person_wealth
    .filter(wealth => wealth.type !== 'loans_debts')
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.type]: (acc[item.type] || 0) + item.amount,
      }),
      {},
    );

  if (Object.keys(wealthSums).length !== 0) {
    chartData = Object.keys(wealthSums).map(type => ({
      name: type,
      value: wealthSums[type],
    }));

    wealthTotal = Object.values(wealthSums).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  }

  const RADIAN = Math.PI / 180;
  let renderCustomizedLabel;
  let chartPaddingAngle = 0;

  if (chartData[0].name !== 'empty') {
    chartPaddingAngle = 5;

    renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 3.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="#EEF0F4"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontSize="20px"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  }

  const renderColorfulLegendText = _val => {
    const value = _val.replace('_', ' ');
    return (
      <span style={{ marginLeft: '6px', marginRight: '10px' }}>
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </span>
    );
  };

  const funds = data && data.person_policies.reduce((acc, item) => ({
    ...acc,
    originalInvestmentAmount: acc.originalInvestmentAmount + item.original_investment_amount,
    mostRecentValue: acc.mostRecentValue + item.most_recent_value,
  }),
    {
      originalInvestmentAmount: 0,
      mostRecentValue: 0
    }
  );

  return (
    <>
      <ClientHeader person={data.persons[0]} nameAsHeader />
      <Flex flexDirection={{ base: 'column', xl: 'row' }}>
        <Flex flexBasis={{ base: '100%', xl: '65%', xxl: user.claims.role === 'client' ? '65%' : '75%' }} flexDirection={{ base: 'column' }}>
          {user.claims.role === 'client' && (
            <Flex 
              className="overview-chart"
              position="relative"
              paddingBottom="30px"
              justifyContent="center"
              color="sky"
              width="100%"
              mb="1.25rem"
            >
              <MyCoverage clientId={clientId} />
            </Flex>
          )}
          <Flex
            className="overview-chart"
            position="relative"
            paddingBottom="30px"
            justifyContent="center"
            color="sky"
            width="100%"
            mb="1.25rem"
          >
            <ResponsiveContainer width="100%" minHeight="500px" maxHeight="550px">
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  innerRadius={140}
                  outerRadius={150}
                  fill="#EEF0F4"
                  paddingAngle={chartPaddingAngle}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  stroke="none"
                >
                  {chartData[0].name !== 'empty' &&
                    chartData.map((entry, index) => (
                      <Cell key={index} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                {chartData[0].name !== 'empty' && (
                  <Legend
                    formatter={renderColorfulLegendText}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                    iconType="circle"
                    iconSize={10}
                  />
                )}
              </PieChart>
            </ResponsiveContainer>
            {user.claims.role === 'client' && (
              <Text color="nude" pos="absolute" left={{ base: "1rem", md: "50px" }} top={{ base: "1.25rem", md: "40px" }}>
                Your wealth profile
              </Text>
            )}
            <Box
              pos="absolute"
              top="41%"
              fontFamily="secondary"
              fontSize="3xl"
              lineHeight="3.75rem"
            >
              {wealthTotal} €
            </Box>
          </Flex>
        </Flex>
        <Flex flexBasis={{ base: '100%', xl: '35%', xxl: user.claims.role === 'client' ? '45%' : '25%' }} flexDirection={{ base: 'column' }} ml={{ base: 0, xl: user.claims.role === 'client' ? '1.25rem' : '0' }}>
          {user.claims.role === 'client' && funds && (funds.originalInvestmentAmount || funds.mostRecentValue) &&
            <Box p="3rem" bg="space" color="sky" mb="1.25rem">
              <Text color="nude" fontWeight="bold" mb="2rem">
                  Fund performance
                </Text>
              { !!funds.originalInvestmentAmount && (
                <Box mb="1.5rem">
                  <Text>Original investment value</Text>
                  <Text as="span" fontFamily="secondary" fontSize="3xl">{funds.originalInvestmentAmount} €</Text>
                </Box>
              )}
              { !!funds.mostRecentValue && (
                <Box>
                  <Text>Most recent valuation </Text>
                  <Text as="span" fontFamily="secondary" fontSize="3xl">{funds.mostRecentValue} €</Text>
                </Box>
              )}
            </Box>
           }
          {userData && (
            <Box bg="nude" p="3rem" w="100%">
              <Text fontWeight="bold" marginBottom="1.25rem">
                Mercurius representative:
              </Text>
              <Text>{userData.name}</Text>
              {userData.user_metadata && (
                <Box
                  as="a"
                  d="block"
                  href={`tel: ${userData.user_metadata.phone_number}`}
                >
                  {userData.user_metadata.phone_number}
                </Box>
              )}
              <Box as="a" d="block" href={`mailto: ${userData.email}`}>
                {userData.email}
              </Box>
            </Box>
          )}

          {['manager', 'sales'].includes(user.claims.role) && (
            <Flex
              bg="white"
              p="5rem 2rem 2.5rem"
              minHeight="360px"
              flexDirection="column"
              justifyContent="center"
              w="100%"
            >
              <LatestNote clientId={clientId} />
            </Flex>
          )}
        </Flex>
      </Flex>
      <MyPoliciesList match={match} clientId={clientId} />
    </>
  );
}

ClientOverview.propTypes = {
  clientId: number.isRequired,
};

export default ClientOverview;
