import React from 'react';
import { string } from 'prop-types';
import { Text } from '@chakra-ui/core';

function FormErrors({ errors }) {
  return errors ? <Text color="danger">{errors}</Text> : null;
}

FormErrors.propTypes = {
  errors: string,
};
FormErrors.defaultProps = {
  errors: '',
};

export default FormErrors;
