import React from 'react';
import { arrayOf, func, bool, string } from 'prop-types';
import { Flex, Button, Box } from '@chakra-ui/core';
import FormErrors from '../FormErrors';

function FormButtons({
  onSubmit,
  onCancel,
  onDelete,
  hideButtons, // ['save', 'delete']
  editable,
  submitting,
  errors,
}) {
  return (
    <Flex
      align="baseline"
      justify="space-between"
      borderTop="1px solid"
      borderColor={editable ? 'transparent' : 'light-grey'}
      bg={editable ? 'sky' : 'white'}
      p="0 1rem 1.25rem"
    >
      {editable && (
        <>
          <Box>
            {onDelete && !hideButtons.includes('delete') && (
              <Button
                type="button"
                variant="ghost"
                isDisabled={submitting}
                color="danger"
                onClick={onDelete}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box>
            <FormErrors errors={errors} />
          </Box>
          <Box>
            {!hideButtons.includes('cancel') && (
              <Button
                type="reset"
                variant="ghost"
                isDisabled={submitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            {!hideButtons.includes('save') && (
              <Button
                type="submit"
                onClick={onSubmit}
                variant="ghost"
                fontWeight="bold"
                isLoading={submitting}
                isDisabled={submitting}
                loadingText="Saving..."
              >
                Save
              </Button>
            )}
          </Box>
        </>
      )}
    </Flex>
  );
}

FormButtons.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  onDelete: func,
  hideButtons: arrayOf(string),
  editable: bool.isRequired,
  submitting: bool.isRequired,
  errors: string,
};
FormButtons.defaultProps = {
  onDelete: null,
  hideButtons: [],
  errors: '',
};

export default FormButtons;
