import React from 'react';
import { shape, number, func, arrayOf, string } from 'prop-types';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import FormField from '../../Form/FormField';

function Pricing({ onSubmit, policy, hideButtons }) {
  return (
    <FormBox heading="Pricing (for internal use only)">
      <FormControls
        initialValues={policy}
        onSubmit={onSubmit}
        hideButtons={hideButtons}
      >
        {({ editable }) => (
          <>
            <FormField
              label="Pricing desciption"
              editable={editable}
              fields={[
                {
                  name: 'pricing_description',
                  placeholder:
                    'Pricing model, customer price, provider price, etc.',
                  type: 'textarea',
                },
              ]}
            />
          </>
        )}
      </FormControls>
    </FormBox>
  );
}

Pricing.propTypes = {
  onSubmit: func,
  policy: shape({
    id: number,
  }).isRequired,
  hideButtons: arrayOf(string),
};

Pricing.defaultProps = {
  policy: {},
  onSubmit: () => {},
  hideButtons: [],
};

export default Pricing;
