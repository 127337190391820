export const typeOptions = [
  { value: 'life', label: 'Life' },
  { value: 'risk', label: 'Risk' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'ulip', label: 'ULIP' },
  { value: 'kmi', label: 'KMI' },
  { value: 'vul', label: 'VUL' },
  { value: 'other', label: 'Other' },
];

export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'expired', label: 'Expired' },
  { value: 'pending', label: 'Pending' },
  { value: 'other', label: 'Other' },
];
