import React from 'react';
import { Box } from '@chakra-ui/core';
import { H3 } from '../../Typography';

function FormBox(props) {
  return (
    <Box
      pos="relative"
      bg="white"
      w="100%"
      p={8}
      color="space"
      marginBottom="1.25rem"
    >
      <H3 as="h2" marginBottom={{ base: '2rem', lg: '3rem' }}>
        {props.heading}
      </H3>
      {typeof props.description !== 'undefined' && (
        <Box as="p" marginBottom="1.5rem">
          {props.description}
        </Box>
      )}
      {props.children}
    </Box>
  );
}

export default FormBox;
