// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { APP_URL, auth0Config } from '../config';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [authClient, setAuth] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const setUserInfo = async client => {
    const authUser = await client.getUser();
    const accessToken = await client.getTokenSilently();
    const idTokenClaims = await client.getIdTokenClaims();

    setUser({
      ...authUser,
      claims: idTokenClaims[auth0Config.idTokenClaimsNamespace],
    });
    setToken(accessToken);
  };

  const logout = client => {
    client.logout({
      client_id: auth0Config.clientId,
      returnTo: APP_URL,
    });
  };

  useEffect(() => {
    const initAuth = async () => {
      const authFromHook = await createAuth0Client(initOptions);
      setAuth(authFromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await authFromHook
          .handleRedirectCallback()
          .catch(() => logout(authFromHook));
        onRedirectCallback(appState);
      }

      if (window.location.search.includes('error=')) {
        await logout(authFromHook);
      }

      const isAuthed = await authFromHook.isAuthenticated();

      if (isAuthed) {
        await setUserInfo(authFromHook);
      }

      setIsAuthenticated(isAuthed);
      setLoading(false);
    };
    initAuth();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await authClient.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    await setUserInfo(authClient);
    // const authUser = await authClient.getUser();
    // const accessToken = await authClient.getTokenSilently();
    // setUser(authUser);
    // setToken(accessToken);

    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await authClient.handleRedirectCallback();
    await setUserInfo(authClient);
    setIsAuthenticated(true);
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => authClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => authClient.loginWithRedirect(...p),
        getTokenSilently: (...p) => authClient.getTokenSilently(...p),
        getTokenWithPopup: (...p) => authClient.getTokenWithPopup(...p),
        logout: () => logout(authClient),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
