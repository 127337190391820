import React from 'react';
import { string } from 'prop-types';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/core';

function Error({ message }) {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon size="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {message}. Please try again.
      </AlertTitle>
    </Alert>
  );
}

Error.defaultProps = {
  message: 'Something went wrong',
};

Error.propTypes = {
  message: string,
};

export default Error;
