import React, { useState, useEffect, useRef, useCallback } from 'react';
import { string, shape } from 'prop-types';
import styled from '@emotion/styled';
import { Box, Select, Flex, Icon, Button, Text } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';
import debounce from 'lodash.debounce';

import { Link } from '../../util/router';
import { useAuth } from '../../util/auth';
import theme from '../../theme';
import Search from '../Search';
import Section from '../Section';
import Container from '../Container';
import Loading from '../Loading';
import Error from '../Error';
import ClientHeader from '../ClientHeader';
import { GET_POLICIES, GET_POLICY_TYPES } from './queries';
import { GET_CLIENTS } from '../ClientList/queries';
import { typeOptions } from './common';
import { ReactComponent as StatusIcon } from '../../assets/images/circle.svg';

const propTypes = {
  match: shape({
    url: string,
    params: shape({
      personId: string,
    }).isRequired,
  }).isRequired,
  clientId: string.isRequired,
};

const LinkStyleStyle = `
  font-weight: bold;
  line-height: 1;
  display: flex;
  min-height: 40px;
`;

const StyledButton = styled.button`
  ${LinkStyleStyle};
  align-self: center;
  padding: 0.5em 1em;
  margin-top: 1em;
`;

const BoldLink = styled(Link)`
  ${LinkStyleStyle};
  font-size: 1.25rem;
  float: right;
  color: #eef0f4;
  align-items: center;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    float: none;
  }
`;

const Status = styled(StatusIcon)`
  display: inline-block;
  margin-left: 15px;

  &.active {
    #Connect-Client-list {
      fill: ${theme.colors.active};
    }
  }

  &.inactive,
  &.expired {
    #Connect-Client-list {
      fill: ${theme.colors.danger};
    }
  }

  &.pending {
    #Connect-Client-list {
      fill: ${theme.colors.warning};
    }
  }

  &.other {
    #Connect-Client-list {
      fill: ${theme.colors.space};
    }
  }
`;

function ClientPolicies({ match, clientId }) {
  const [sortOrder, setSortOrder] = useState('asc');
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState('');
  const [noMoreData, setNoMoreData] = useState(false);
  const [type, setType] = useState('');
  const searchRef = useRef();
  const { user } = useAuth();

  let offset = 0;

  const { data: clientData } = useQuery(GET_CLIENTS, {
    variables: {
      where: {
        id: { _eq: clientId },
      },
      search: '',
      limit: 1,
      offset: 0,
    },
  });

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_POLICIES, {
    variables: {
      order_by: {
        type: 'asc',
      },
      limit: 10,
      offset,
      search: query,
      where: {
        _and: {
          person_id: { _eq: clientId },
          type: !type ? {} : { _eq: type },
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { error: typesError, data: typesData } = useQuery(GET_POLICY_TYPES);

  const handleSearch = event => setKeyword(event.target.value);
  const handleFilter = event => setType(event.target.value);
  const handleMore = () => {
    offset += 10;
    fetchMore({
      variables: {
        offset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        // NOTE: TODO: setNoMoreData after first fetch.
        if (fetchMoreResult.policies.length < 10) setNoMoreData(true);

        if (prev && fetchMoreResult) {
          return Object.assign({}, prev, {
            policies: [...prev.policies, ...fetchMoreResult.policies],
          });
        }
      },
    });
  };

  const onSort = column => {
    let limit = null;
    if (data) limit = data.policies.length;
    if (sortOrder === 'asc') {
      setSortOrder('desc');
      refetch({
        order_by: {
          [column]: 'desc',
        },
        limit,
      });
    } else {
      setSortOrder('asc');
      refetch({
        order_by: {
          [column]: 'asc',
        },
        limit,
      });
    }
  };

  const debouncedSetQuery = useCallback(debounce(val => setQuery(val), 1000), [
    setQuery,
  ]);

  useEffect(() => {
    debouncedSetQuery(keyword);
  }, [keyword, debouncedSetQuery]);

  if (loading) {
    return <Loading />;
  }
  if (error || typesError) {
    return (
      <Section>
        <Container>
          <Error />
        </Container>
      </Section>
    );
  }

  return (
    <>
      <Box m="0" p="">
        <ClientHeader
          title="Policies"
          person={clientData && clientData.persons[0]}
          displayContactInfo={false}
        >
          {['manager', 'sales'].includes(user.claims.role) && (
            <BoldLink to={`${match.url}/new`}>+ Add new</BoldLink>
          )}
          <Search
            ref={searchRef}
            size="lg"
            borderColor="transparent"
            rounded="0"
            handleSearch={handleSearch}
            keyword={keyword}
          />
        </ClientHeader>
      </Box>
      <Flex
        direction="column"
        mt="200px"
        overflowY="auto"
        p={{ base: '0 1rem 10px', sm: '0 0 10px' }}
      >
        {data.policies.length ? (
          <>
            <Flex>
              <Select
                placeholder="Type"
                maxW="150px"
                marginRight="10px"
                borderColor="space"
                onChange={handleFilter}
                defaultValue={type}
              >
                {!!typesData &&
                  typesData.person_policies &&
                  typesData.person_policies.map(
                    policy =>
                      policy.type && (
                        <option key={policy.id} value={policy.type}>
                          {typeOptions.map(
                            option =>
                              option.value === policy.type && option.label,
                          )}
                        </option>
                      ),
                  )}
              </Select>
            </Flex>
            <table>
              <thead>
                <tr>
                  <Box as="th" p="5px">
                    <Button onClick={() => onSort('type')}>
                      Type
                      {
                        <Icon
                          name={`chevron-${
                            sortOrder === 'asc' ? 'up' : 'down'
                          }`}
                          size="1.75rem"
                          m="3px 0 0 5px"
                        />
                      }
                    </Button>
                  </Box>
                  <Box as="th" p="5px">
                    <Button onClick={() => onSort('provider')}>
                      Provider
                      {
                        <Icon
                          name={`chevron-${
                            sortOrder === 'asc' ? 'up' : 'down'
                          }`}
                          size="1.75rem"
                          m="3px 0 0 5px"
                        />
                      }
                    </Button>
                  </Box>
                  <Box as="th">Number</Box>
                  <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                    Insured
                  </Box>
                  <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                    Value
                  </Box>
                  <Box as="th" d={{ base: 'none', xl: 'table-cell' }} p="0">
                    Start date
                  </Box>
                  <Box
                    as="th"
                    d={{ base: 'none', lg: 'table-cell' }}
                    textAlign="right"
                  >
                    <Button onClick={() => onSort('status')}>
                      Status
                      {
                        <Icon
                          name={`chevron-${
                            sortOrder === 'asc' ? 'up' : 'down'
                          }`}
                          size="1.75rem"
                          m="3px 0 0 5px"
                        />
                      }
                    </Button>
                  </Box>
                </tr>
              </thead>

              <tbody>
                {!!data &&
                  data.policies &&
                  data.policies.map(policy => (
                    <tr key={policy.id}>
                      <Box as="td" className="name">
                        <Link to={`${match.url}/${policy.id}`}>
                          {typeOptions.map(
                            option =>
                              option.value === policy.type && option.label,
                          )}
                        </Link>
                      </Box>
                      <Box as="td">
                        <Link to={`${match.url}/${policy.id}`}>
                          {policy.provider}
                        </Link>
                      </Box>
                      <Box as="td">{policy.number}</Box>
                      <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                        {policy.insured.map(insured => insured.name)}
                      </Box>
                      <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                        {policy.risk_cover_amount &&
                          `${policy.risk_cover_amount} ${policy.risk_cover_currency}`}
                      </Box>
                      <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                        {policy.start_date}
                      </Box>
                      <Box
                        as="td"
                        d={{ base: 'none', lg: 'table-cell' }}
                        textAlign="right"
                        textTransform="capitalize"
                      >
                        {policy.status && policy.status}
                        {policy.status && <Status className={policy.status} />}
                      </Box>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* {!noMoreData && (
              <StyledButton type="button" onClick={handleMore}>
                More
              </StyledButton>
            )} */}
          </>
        ) : (
          <Text ml="110px">There are no policies yet.</Text>
        )}
      </Flex>
    </>
  );
}

ClientPolicies.propTypes = propTypes;

export default ClientPolicies;
