import React, { useState, useEffect, useCallback, useRef } from 'react';
import { shape, string } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Box, Button, Select, Flex, Icon, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';

import { Link } from '../../util/router';
import Section from '../Section';
import Container from '../Container';
import Loading from '../Loading';
import Error from '../Error';
import { H2 } from '../Typography';
import Search from '../Search';
import { GET_CLIENTS, GET_CITIES } from './queries';
import theme from '../../theme';

const LinkStyleStyle = `
  font-weight: bold;
  line-height: 1;
  display: flex;
  min-height: 40px;
`;

const StyledButton = styled.button`
  ${LinkStyleStyle};
  align-self: center;
  padding: 0.5em 1em;
  margin-top: 1em;
`;

const BoldLink = styled(Link)`
  ${LinkStyleStyle};
  font-size: 1.25rem;
  float: right;
  color: #eef0f4;
  align-items: center;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    float: none;
  }
`;

const ClientList = ({ match }) => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState('');
  const [city, setCity] = useState('');
  // const [sortBy, setSortBy] = useState(''); // TODO: implement this I guess
  const [noMoreData, setNoMoreData] = useState(false);
  const searchRef = useRef();

  let offset = 0;

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_CLIENTS, {
    variables: {
      order_by: {
        last_name: 'asc',
      },
      limit: 10,
      offset,
      search: query,
      where: {
        _and: {
          city: !city ? {} : { _eq: city },
          parent_id: { _is_null: true },
          grandparent_id: { _is_null: true },
          spouse_id: { _is_null: true },
          deleted: { _neq: true },
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { error: citiesError, data: citiesData } = useQuery(GET_CITIES);

  const handleSearch = event => setKeyword(event.target.value);
  const handleFilter = event => setCity(event.target.value);
  const handleMore = () => {
    offset += 10;
    fetchMore({
      variables: {
        offset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        // NOTE: TODO: setNoMoreData after first fetch.
        if (fetchMoreResult.persons.length < 10) setNoMoreData(true);

        if (prev && fetchMoreResult) {
          return Object.assign({}, prev, {
            persons: [...prev.persons, ...fetchMoreResult.persons],
          });
        }
      },
    });
  };

  const debouncedSetQuery = useCallback(debounce(val => setQuery(val), 1000), [
    setQuery,
  ]);

  useEffect(() => {
    // hide more button if theres no more clients
    if (data && data.persons.length >= data.total.aggregate.count) {
      setNoMoreData(true);
    }

    debouncedSetQuery(keyword);
  }, [keyword, debouncedSetQuery, data]);

  if (loading) {
    return <Loading />;
  }
  if (error || citiesError) {
    return (
      <Section>
        <Container>
          <Error />
        </Container>
      </Section>
    );
  }

  const onSort = column => {
    let limit = null;
    if (data) limit = data.persons.length;
    if (sortOrder === 'asc') {
      setSortOrder('desc');
      refetch({
        order_by: {
          [column]: 'desc',
        },
        limit,
      });
    } else {
      setSortOrder('asc');
      refetch({
        order_by: {
          [column]: 'asc',
        },
        limit,
      });
    }
  };

  const renderPolicy = (policies, type) => {
    return (
      policies.filter(policy => policy.type === type).length !== 0 && (
        <Icon name="check" size="1.5rem" color="light-grey" />
      )
    );
  };

  return (
    <Section>
      <Container>
        <Box p="0 2.5rem">
          <BoldLink to="/clients/new">+ Add new</BoldLink>
          <H2 as="h2" m={{ base: '3rem 0 3rem', sm: '7rem 0 3rem' }}>
            Clients
          </H2>
          <Text marginBottom="0.5rem" color="nude" fontSize="xl">
            You have {data && data.total.aggregate.count} clients
          </Text>

          <Search
            ref={searchRef}
            size="lg"
            borderColor="transparent"
            rounded="0"
            handleSearch={handleSearch}
            keyword={keyword}
          />
        </Box>
        <Flex
          direction="column"
          marginTop="6rem"
          overflowY="auto"
          backgroundColor="sky"
          p={{ base: '0 1rem 10px', sm: '0 0 10px' }}
        >
          <Flex paddingTop="3.25rem">
            <Select
              placeholder="City"
              maxW="150px"
              marginRight="10px"
              borderColor="space"
              onChange={handleFilter}
              defaultValue={city}
            >
              {!!citiesData &&
                citiesData.persons &&
                citiesData.persons.map(
                  client =>
                    client.city && (
                      <option key={client.id} value={client.city}>
                        {client.city}
                      </option>
                    ),
                )}
            </Select>
          </Flex>
          <table>
            <thead>
              <tr>
                <Box as="th">
                  <Button onClick={() => onSort('last_name')}>
                    Name
                    {
                      <Icon
                        name={`chevron-${sortOrder === 'asc' ? 'up' : 'down'}`}
                        size="1.75rem"
                        m="3px 0 0 5px"
                      />
                    }
                  </Button>
                </Box>
                <Box as="th">
                  <Button onClick={() => onSort('city')}>
                    City
                    {
                      <Icon
                        name={`chevron-${sortOrder === 'asc' ? 'up' : 'down'}`}
                        size="1.75rem"
                        m="3px 0 0 5px"
                      />
                    }
                  </Button>
                </Box>
                <Box as="th">Phone</Box>
                <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                  Life
                </Box>
                <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                  Risk
                </Box>
                <Box as="th" d={{ base: 'none', xl: 'table-cell' }}>
                  Lifestyle
                </Box>
                <Box as="th" d={{ base: 'none', lg: 'table-cell' }}>
                  Note
                </Box>
                {/* <Box as="th">Priority</Box> */}
              </tr>
            </thead>

            <tbody>
              {!!data &&
                data.persons &&
                data.persons.map(user => (
                  <tr key={user.id}>
                    <Box as="td" className="name">
                      <Link to={`${match.url}/${user.id}`}>
                        {user.last_name}, {user.first_name}
                      </Link>
                    </Box>
                    <Box as="td">{user.city}</Box>
                    <Box as="td">{user.phone_number}</Box>
                    <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                      {user.policies && renderPolicy(user.policies, 'life')}
                    </Box>
                    <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                      {user.policies && renderPolicy(user.policies, 'risk')}
                    </Box>
                    <Box as="td" d={{ base: 'none', xl: 'table-cell' }}>
                      {user.policies &&
                        renderPolicy(user.policies, 'lifestyle')}
                    </Box>
                    <Box as="td" d={{ base: 'none', lg: 'table-cell' }}>
                      {user.notes[0] && user.notes[0].content}
                    </Box>
                    {/* <Box as="td">
                      To-do
                      <Image
                        src={CircleIcon}
                        alt="status"
                        display="inline-block"
                        marginLeft="15px"
                      />
                    </Box> */}
                  </tr>
                ))}
            </tbody>
          </table>
          {!noMoreData && (
            <StyledButton onClick={handleMore}>More</StyledButton>
          )}
        </Flex>
      </Container>
    </Section>
  );
};

ClientList.propTypes = {
  match: shape({
    url: string,
  }).isRequired,
};

export default ClientList;
