export const __DEV__ = process.env.NODE_ENV !== 'production';

export const APP_URL = __DEV__
  ? 'http://localhost:3030'
  : 'https://connect.mercuriusassurance.com';

export const API_URL = __DEV__
  ? 'http://localhost:8080'
  : 'https://connect-api.mercuriusassurance.com';

export const auth0Config = {
  domain: 'mercurius-connect.eu.auth0.com',
  clientId: 'AYTP6DCw63jLVYMXjYQIp0tmbCHXOkYs',
  audience: 'https://api.mercurius-connect.com',
  scope: 'openid',
  idTokenClaimsNamespace: 'https://mercurius-connect.com/claims',
};
