import React, { useState } from 'react';
import { shape, func, string } from 'prop-types';
import { Flex, Textarea, Button, Input } from '@chakra-ui/core';
import useForm, { FormContext } from 'react-hook-form';
import Error from '../../Error';

function NoteForm({ note, clientId, onSave, onCancel, onDelete }) {
  let initialValues = false;
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const newNote = Object.keys(note).length === 0;

  if (!newNote) {
    initialValues = {
      id: note.id,
      content: note.content,
    };
  }

  const formMethods = useForm({
    defaultValues: {
      person_id: clientId,
      ...initialValues,
    },
  });

  const { handleSubmit, register, errors, setValue } = formMethods;

  const onSubmit = async formValues => {
    setValue('content', '');
    setSaving(true);
    await onSave(formValues).catch(error => {
      if (error.message) {
        setSaveError(error.message);
      } else {
        setSaveError('Unable to save the note');
      }
    });
    setSaving(false);
  };

  if (saveError) {
    return <Error message={saveError} />;
  }

  return (
    <>
      <FormContext {...formMethods}>
        <form>
          <Flex
            m="1.25rem 0"
            p="1.25rem 1.25rem 10px"
            alignItems="center"
            bg="sky"
            flexDirection={{ base: 'column', xl: 'row' }}
          >
            <Flex flexBasis="90%" width="100%">
              <Textarea
                key="content"
                id="content"
                name="content"
                placeholder={newNote ? 'Add new note' : undefined}
                ref={register({ required: true })}
                className={`${errors['content'] ? 'is-error' : ''}`}
                isInvalid={!!errors['content']}
                rounded="0"
                marginBottom="1rem"
                borderColor="transparent"
                _invalid={{
                  border: '1px solid',
                  borderColor: 'danger',
                }}
              />
              {!newNote && <Input name="id" type="hidden" ref={register({})} />}
              <Input name="person_id" type="hidden" ref={register({})} />
            </Flex>
            <Flex flexBasis="10%" width="100%" justifyContent="space-between">
              {!newNote && (
                <Button
                  type="button"
                  variant="ghost"
                  color="danger"
                  onClick={handleSubmit(onDelete)}
                  marginBottom="1rem"
                  isDisabled={saving}
                >
                  Delete
                </Button>
              )}
              <Button
                type="button"
                onClick={handleSubmit(onSubmit)}
                variant="ghost"
                fontWeight="bold"
                marginBottom="1rem"
                isLoading={saving}
                isDisabled={saving}
                loadingText="Saving..."
              >
                Save
              </Button>
              {!newNote && (
                <Button
                  type="reset"
                  variant="ghost"
                  onClick={onCancel}
                  marginBottom="1rem"
                  isDisabled={saving}
                >
                  Cancel
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      </FormContext>
    </>
  );
}

NoteForm.propTypes = {
  note: shape({}),
  clientId: string,
  onSave: func,
  onCancel: func,
  onDelete: func,
};
NoteForm.defaultProps = {
  note: {},
  clientId: false,
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
};

export default NoteForm;
