import React from 'react';
import { string } from 'prop-types';
import { Text, Image, Box } from '@chakra-ui/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { Link } from '../../../util/router';
import ArrowIcon from '../../../assets/images/arrow.svg';
import Loading from '../../Loading';
import Error from '../../Error';

const GET_PERSON_NOTES = gql`
  query getPersonNotes($personId: Int!) {
    person_notes(
      where: { person_id: { _eq: $personId } }
      order_by: { updated_at: desc }
    ) {
      id
      person_id
      content
      created_at
      updated_at
    }
  }
`;

const printDate = dateString => {
  const date = new Date(dateString);
  return moment(date).format('D.M.YYYY');
};

function LatestNote({ clientId }) {
  const { error, loading, data } = useQuery(GET_PERSON_NOTES, {
    variables: { personId: clientId },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return data.person_notes.length ? (
    <>
      <Text fontWeight="bold" marginBottom="1.25rem">
        Notes
      </Text>
      <Text color="info" mb=".5rem">{printDate(data.person_notes[0].updated_at)}</Text>
      <Text>{data.person_notes[0].content}</Text>
      <Link to={`/clients/${clientId}/notes`}>
        <Image
          src={ArrowIcon}
          float="right"
          margin="20px 0 0 auto"
          transform="rotate(180deg)"
        />
      </Link>
    </>
  ) : (
    <Box fontWeight="bold" marginBottom="1.25rem" textAlign="center">
      <Link to={`/clients/${clientId}/notes`}>Add notes</Link>
    </Box>
  );
}

LatestNote.propTypes = {
  clientId: string.isRequired,
};

export default LatestNote;
