import React from 'react';
import { Flex, Spinner } from '@chakra-ui/core';

function Loading() {
  return (
    <Flex h="100vh" alignItems="center" justifyContent="center">
      <Spinner
        thickness="25px"
        speed=".75s"
        emptyColor="sky"
        color="space"
        size="xl"
        label="loading"
        h="100px"
        w="100px"
      />
    </Flex>
  );
}

export default Loading;
