import React, { useState } from 'react';
import { shape, func, string } from 'prop-types';
import { Flex, Text, Box, Button, Image } from '@chakra-ui/core';
import moment from 'moment';
import NoteForm from '../NoteForm';
import { useAuth0User } from '../../../hooks/Auth0';
import Error from '../../Error';

function NoteWrapper({ note, clientId, user, onSave, onDelete }) {
  const [editable, setEditable] = useState(false);
  const userData = useAuth0User(note.author_id);
  const [saveError, setSaveError] = useState(false);

  const onEdit = () => {
    setEditable(true);
  };

  const onCancel = () => {
    setEditable(false);
  };

  const onRemove = formValues => {
    setEditable(false);
    onDelete(formValues);
  };

  const onSubmit = async formValues => {
    setEditable(false);
    await onSave(formValues).catch(error => {
      if (error.message) {
        setSaveError(error.message);
      } else {
        setSaveError('Unable to save the note');
      }
    });
  };

  if (saveError) {
    return <Error message={saveError} />;
  }

  const printDate = dateString => {
    const date = new Date(dateString);
    return moment(date).format('D.M.YYYY H:mm');
  };

  return (
    <>
      {editable ? (
        <NoteForm
          note={note}
          clientId={clientId}
          user={user}
          onSave={onSubmit}
          onCancel={onCancel}
          onDelete={onRemove}
        />
      ) : (
        <>
          <Flex
            bg="white"
            w="100%"
            p="2rem 1rem"
            color="space"
            borderBottom="1px solid"
            borderColor="light-grey"
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <Flex
              flexBasis="5%"
              minW="80px"
              marginBottom={{ base: '1rem', lg: '0' }}
            >
              <Image
                bg="light-grey"
                borderRadius="3rem"
                width="60px"
                height="60px"
                src={userData ? userData.picture : ''}
              />
            </Flex>
            <Flex
              flexBasis="85%"
              flexDirection="column"
              marginBottom={{ base: '1rem', lg: '0' }}
            >
              <Text marginBottom="5px">
                <Box as="span" fontWeight="bold">
                  {userData && `${userData.name}: `}
                </Box>
                {note.content}
              </Text>
              <Text color="info">{printDate(note.updated_at)}</Text>
            </Flex>
            <Flex flexBasis="10%">
              {note.author_id && note.author_id === user.sub && (
                <Button variant="ghost" fontWeight="bold" onClick={onEdit}>
                  Edit
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
}

NoteForm.propTypes = {
  note: shape({}),
  clientId: string,
  onSave: func,
  onDelete: func,
};
NoteForm.defaultProps = {
  note: {},
  clientId: false,
  onSave: () => {},
  onDelete: () => {},
};

export default NoteWrapper;
