import React from 'react';
import { shape, string } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Box } from '@chakra-ui/core';
import { Route, ProtectedRoute, Switch } from '../../util/router';
import NotFound from '../../pages/notfound';
import Loading from '../Loading';
import Section from '../Section';
import Container from '../Container';
import ClientNavbar from '../ClientNavbar';
import ClientOverview from '../ClientOverview';
import ClientPersonalInfo from '../ClientPersonalInfo';
import ClientWealthInfo from '../ClientWealthInfo';
import ClientNotes from '../ClientNotes';
import ClientPolicies from '../ClientPolicies';
import NewPolicy from '../ClientPolicies/NewPolicy';
import Policy from '../ClientPolicies/Policy';
import ClientSettings from '../ClientSettings';

function ClientProfile({ match, location, personId }) {
  const clientId = match.params.clientId || personId;
  const { data, loading, error } = useQuery(gql`
    {
      person: persons_by_pk(id: ${clientId}) {
        id
        deleted
      }
    }
  `);

  if (loading) {
    return <Loading />;
  }
  if (error || !data.person || data.person.deleted) {
    return <NotFound location={location} />;
  }

  return (
    <Section>
      <Container
        d="flex"
        alignItems="stretch"
        flexDirection={{ base: 'column', xl: 'row' }}
        seamless
      >
        <Box flex="1" flexBasis="20%" minWidth="315px">
          <ClientNavbar match={match} />
        </Box>
        <Box flex="1" flexBasis="80%" minW={{ xl: '80%' }}>
          <Route
            exact
            path={match.url}
            render={(props) => <ClientOverview {...props} clientId={clientId} />}
          />
          <Route
            exact
            path={`${match.url}/profile`}
            render={() => <ClientPersonalInfo clientId={clientId} />}
          />
          <Route
            exact
            path={`${match.url}/wealth`}
            render={() => <ClientWealthInfo clientId={clientId} />}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/notes`}
            render={() => <ClientNotes clientId={clientId} />}
            roles={['manager', 'sales']}
          />
          <Route
            exact
            path={`${match.url}/policies`}
            render={props => <ClientPolicies {...props} clientId={clientId} />}
          />

          <Switch>
            <ProtectedRoute
              path={`${match.url}/policies/new`}
              render={() => <NewPolicy clientId={clientId} />}
              roles={['manager', 'sales']}
            />
            <Route
              path={`${match.url}/policies/:policyId`}
              component={Policy}
            />
          </Switch>
          <ProtectedRoute
            roles={['manager', 'sales']}
            exact
            path={`${match.url}/settings`}
            render={() => <ClientSettings clientId={clientId} />}
          />
        </Box>
      </Container>
    </Section>
  );
}

ClientProfile.propTypes = {
  match: shape({
    url: string,
    params: shape({
      personId: string,
    }).isRequired,
  }).isRequired,
  location: shape({
    pathname: string,
  }).isRequired,
};

export default ClientProfile;
