import React, { useState } from 'react';
import { Button, Input, Box, Flex, Text } from '@chakra-ui/core';
import useForm, { FormContext } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Section from '../Section';
import Container from '../Container';
import { H2 } from '../Typography';
import { Redirect } from '../../util/router';
import Error from '../Error';

const ADD_PERSON = gql`
  mutation AddPerson($formValues: persons_insert_input!) {
    insert_persons(objects: [$formValues]) {
      returning {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

function NewClient() {
  const formMethods = useForm();
  const { handleSubmit, register, errors } = formMethods;
  const [addPerson] = useMutation(ADD_PERSON);
  const [clientId, setClientId] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const onSubmit = formValues => {
    addPerson({
      variables: {
        formValues,
      },
    })
      .then(result => {
        setClientId(result.data.insert_persons.returning['0'].id);
      })
      .catch(error => {
        if (error.message) {
          setSaveError(error.message);
        } else {
          setSaveError('Unable to create a new client');
        }
      });
  };

  if (clientId) {
    return <Redirect to={`/clients/${clientId}/profile`} />;
  }

  if (saveError) {
    return (
      <Section>
        <Container>
          <Error message={saveError} />
        </Container>
      </Section>
    );
  }

  return (
    <Section>
      <Container margin={{ base: '0 1.5rem', sm: '0' }}>
        <H2 as="h1" m={{ base: '3rem 0 2.5rem', sm: '7rem 0 2.5rem' }}>
          Add new client
        </H2>
        <Text marginBottom="6.5rem" color="nude" fontSize="xl">
          Start by adding new client
        </Text>
        <Box
          bg="white"
          w="100%"
          p={8}
          color="space"
          marginBottom="1.25rem"
          maxW="50rem"
        >
          <FormContext {...formMethods}>
            <form>
              <Flex
                p="20px 20px 10px"
                bg="sky"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Flex
                  flexBasis="85%"
                  flexDirection="column"
                  justify="flex-start"
                >
                  <Input
                    key="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    ref={register({ required: true })}
                    className={`${errors['email'] ? 'is-error' : ''}`}
                    isInvalid={!!errors['email']}
                    rounded="0"
                    marginBottom="1rem"
                    _invalid={{
                      border: '1px solid',
                      borderColor: 'danger',
                    }}
                  />
                  <Input
                    key="first_name"
                    placeholder="First name"
                    id="first_name"
                    name="first_name"
                    ref={register({ required: true })}
                    className={`${errors['first_name'] ? 'is-error' : ''}`}
                    isInvalid={!!errors['first_name']}
                    rounded="0"
                    marginBottom="1rem"
                    _invalid={{
                      border: '1px solid',
                      borderColor: 'danger',
                    }}
                  />
                  <Input
                    key="last_name"
                    placeholder="Last name"
                    id="last_name"
                    name="last_name"
                    ref={register({ required: true })}
                    className={`${errors['last_name'] ? 'is-error' : ''}`}
                    isInvalid={!!errors['last_name']}
                    rounded="0"
                    marginBottom="1rem"
                    _invalid={{
                      border: '1px solid',
                      borderColor: 'danger',
                    }}
                  />
                </Flex>
                <Flex
                  flexBasis="15%"
                  flexDirection="column"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    variant="ghost"
                    fontWeight="bold"
                    marginBottom="1rem"
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormContext>
        </Box>
      </Container>
    </Section>
  );
}

export default NewClient;
