import React from 'react';
import { string } from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAuth } from '../../util/auth';
import WealthForm from './WealthForm';
import Loading from '../Loading';
import Error from '../Error';
import ClientHeader from '../ClientHeader';

const WEALTH_FIELDS = gql`
  fragment wealthFields on person_wealth {
    id
    person_id
    type
    amount
    currency
    name
  }
`;

const GET_PERSON_WEALTH = gql`
  query getPersonWealth($personId: Int!) {
    person_wealth(where: { person_id: { _eq: $personId } }) {
      ...wealthFields
    }
    wealth_types {
      name
    }
    currencies {
      name
    }
    persons(where: { id: { _eq: $personId } }) {
      first_name
      last_name
      city
      email
      phone_number
      date_of_birth
    }
  }
  ${WEALTH_FIELDS}
`;

const UPDATE_PERSON_WEALTH = gql`
  mutation UpdatePersonWealth(
    $wealthId: Int!
    $formValues: person_wealth_set_input
  ) {
    update_person_wealth(where: { id: { _eq: $wealthId } }, _set: $formValues) {
      returning {
        ...wealthFields
      }
    }
  }
  ${WEALTH_FIELDS}
`;

const ADD_PERSON_WEALTH = gql`
  mutation AddPersonWealth($formValues: person_wealth_insert_input!) {
    insert_person_wealth(objects: [$formValues]) {
      returning {
        ...wealthFields
      }
    }
  }
  ${WEALTH_FIELDS}
`;

const DELETE_PERSON_WEALTH = gql`
  mutation DeletePersonWealth($id: Int!) {
    delete_person_wealth(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

function ClientWealthInfo({ clientId }) {
  const { user } = useAuth();

  const formSections = [
    {
      wealthType: 'account_deposit',
      heading: 'Accounts and deposits',
      description: `${
        user.claims.role === 'client'
          ? 'Enter the aggregated value of all your accounts and deposits.'
          : 'The aggregated value of all accounts and deposits reported by the client.'
      }`,
      items: [
        {
          label: 'Total value',
          name: 'total_value',
          placeholder: 'Total value',
        },
      ],
    },
    {
      wealthType: 'investments',
      heading: 'Investments',
      description: `${
        user.claims.role === 'client'
          ? 'Enter the total value for each applicable investment type.'
          : 'The value of all investments as reported by the client.'
      }`,
      items: [
        {
          label: 'Stocks',
          name: 'stocks',
          placeholder: 'Stocks and vested shares',
        },

        {
          label: 'Bonds',
          name: 'bonds',
          placeholder: 'Bonds',
        },

        {
          label: 'Mutual funds',
          name: 'funds',
          placeholder: 'Mutual funds',
        },

        {
          label: 'Other instruments',
          name: 'other_instruments',
          placeholder: 'Other instruments',
        },
      ],
    },
    {
      wealthType: 'insurance',
      heading: 'Insurance',
      description: `${
        user.claims.role === 'client'
          ? 'Any current insurance or pension plans you hold. Enter the total value for each type.'
          : 'The reported value of all insurance instruments, including non-Mercurius products.'
      }`,
      items: [
        {
          label: 'ULIP structures',
          name: 'ulip_structures',
          placeholder: `${
            user.claims.role === 'client'
              ? 'ULIP structures (“Unit-linked insurance plan” - a policy that combines insurance and investment)'
              : 'ULIP structures'
          }`,
        },
        {
          label: 'Capitalization agreements',
          name: 'capitalization_agreements',
          placeholder: `${
            user.claims.role === 'client'
              ? 'Capitalization agreements (your current policies)'
              : 'Capitalization agreements'
          }`,
        },
        {
          label: 'Pensions',
          name: 'pensions',
          placeholder: `${
            user.claims.role === 'client'
              ? 'Pensions (your private and public pensions)'
              : 'Pensions'
          }`,
        },
        {
          label: 'Life insurances',
          name: 'life_insurances',
          placeholder: `${
            user.claims.role === 'client'
              ? 'Life insurances (your current policies)'
              : 'Life insurances'
          }`,
        },
      ],
    },
    {
      wealthType: 'real_estate',
      heading: 'Real estate',
      description: `${
        user.claims.role === 'client'
          ? 'Enter the total value of your properties and rental income, regardless of country.'
          : 'The value of all real estate properties and rental income reported by the client.'
      }`,
      items: [
        {
          label: 'Properties',
          name: 'properties',
          placeholder: 'Properties',
        },
        {
          label: 'Rental income (annual)',
          name: 'rental_income',
          placeholder: 'Rental income (annual)',
        },
        {
          label: 'Other real estate',
          name: 'other',
          placeholder: 'Other real estate',
        },
      ],
    },
    {
      wealthType: 'company',
      heading: 'Company wealth',
      description: `${
        user.claims.role === 'client'
          ? 'If you own one or more companies, indicate the total value of your combined holdings.'
          : 'The aggregated company wealth reported by the client.'
      }`,
      items: [
        {
          label: 'Total value',
          name: 'total_value',
          placeholder: 'Total company wealth value',
        },
      ],
    },
    {
      wealthType: 'other_assets',
      heading: 'Other assets',
      description: `${
        user.claims.role === 'client'
          ? 'Enter the combined value of any other high-value assets you own, such as vehicles, artwork, or trusts.'
          : 'The combined total value of all the asset types listed below, as reported by the client.'
      }`,
      items: [
        {
          label: 'Vehicles',
          name: 'vehicles',
          placeholder: 'Vehicles',
        },
        {
          label: 'Art',
          name: 'art',
          placeholder: 'Art',
        },
        {
          label: 'Yachts',
          name: 'yachts',
          placeholder: 'Yachts',
        },
        {
          label: 'Estate and inheritance trusts',
          name: 'estate_and_inheritance_trusts',
          placeholder: 'Estate and inheritance trusts',
        },
      ],
    },
    {
      wealthType: 'loans_debts',
      heading: 'Loans and debts',
      items: [
        {
          label: 'Property',
          name: 'property',
          placeholder: 'Property',
        },
        {
          label: 'Investments',
          name: 'investments',
          placeholder: 'Investments',
        },
        {
          label: 'Outstanding credit',
          name: 'outstanding_credit',
          placeholder: 'Total value of outstanding credit',
        },
      ],
    },
  ];

  const { error, loading, data } = useQuery(GET_PERSON_WEALTH, {
    variables: { personId: clientId },
  });

  const [updateWealthItem] = useMutation(UPDATE_PERSON_WEALTH, {
    refetchQueries: [
      {
        query: GET_PERSON_WEALTH,
        variables: {
          personId: clientId,
        },
      },
    ],
  });
  const [addWealthItem] = useMutation(ADD_PERSON_WEALTH, {
    refetchQueries: [
      {
        query: GET_PERSON_WEALTH,
        variables: {
          personId: clientId,
        },
      },
    ],
  });
  const [deleteWealthItem] = useMutation(DELETE_PERSON_WEALTH, {
    refetchQueries: [
      {
        query: GET_PERSON_WEALTH,
        variables: {
          personId: clientId,
        },
      },
    ],
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const upsertWealthItems = wealthType => items => {
    return Promise.all(
      items.map(item => {
        const formValues = {
          type: wealthType,
          name: item.name,
          amount: Number(item.amount),
          currency: item.currency,
          person_id: Number(clientId),
        };

        if (item.id && !item.amount) {
          // console.log('deleting', formValues);
          return deleteWealthItem({
            variables: {
              id: item.id,
            },
          });
        }

        if (item.id) {
          // console.log('updating', formValues);
          return updateWealthItem({
            variables: {
              wealthId: item.id,
              formValues,
            },
          });
        }

        if (item.amount) {
          // console.log('adding', formValues);
          return addWealthItem({
            variables: {
              formValues,
            },
          });
        }

        // the item has neither id (exiting) nor amount (new), no action required
        return Promise.resolve();
      }),
    );
  };

  const {
    person_wealth: wealthData,
    wealth_types: wealthTypes,
    currencies,
  } = data;

  // console.log('loaded wealth data', data);

  return (
    <>
      <ClientHeader title="Wealth info" person={data.persons[0]} />
      {formSections.map(
        section =>
          // check that this wealthType exists in the db before rendering a form for it
          wealthTypes.find(type => type.name === section.wealthType) && (
            <WealthForm
              key={section.wealthType}
              heading={section.heading}
              description={section.description}
              onSave={upsertWealthItems(section.wealthType)}
              items={section.items.map(names => ({
                // grab the labels and field names from the form config
                ...names,
                // and get the current values from the graphql query response
                ...(wealthData.find(
                  item =>
                    item.type === section.wealthType &&
                    item.name === names.name,
                ) || {}),
              }))}
              currencies={currencies}
            />
          ),
      )}
    </>
  );
}

ClientWealthInfo.propTypes = {
  clientId: string.isRequired,
};

export default ClientWealthInfo;
