import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { string, shape } from 'prop-types';
import { Text, Box, Flex } from '@chakra-ui/core';
import { useAuth } from '../../../util/auth';
import { H2 } from '../../Typography';
import BasicInformation from '../BasicInformation';
import Loading from '../../Loading';
import Error from '../../Error';
import DatesAndCoverage from '../DatesAndCoverage';
import PaymentInformation from '../PaymentInformation';
import Pricing from '../Pricing';
import Beneficiaries from '../Beneficiaries';
import { parseApolloError } from '../../../util/apollo';
import PolicyAuditLog from '../../AuditLog/PolicyAuditLog';
import { typeOptions } from '../common';
import {
  GET_POLICY,
  UPDATE_POLICY,
  ADD_INSURED,
  UPDATE_INSURED,
  DELETE_INSURED,
  ADD_BENEFICIARY,
  UPDATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  ADD_ASSET_MANAGER,
  UPDATE_ASSET_MANAGER,
  DELETE_ASSET_MANAGER,
} from '../queries';

function Policy({ match }) {
  const { user } = useAuth();
  const { policyId } = match.params;
  const { error, loading, data } = useQuery(GET_POLICY, {
    variables: { policyId },
  });

  const [updatePolicy] = useMutation(UPDATE_POLICY, {
    refetchQueries: [
      {
        query: GET_POLICY,
        variables: {
          policyId,
        },
      },
    ],
  });
  const [addInsured] = useMutation(ADD_INSURED);
  const [updateInsured] = useMutation(UPDATE_INSURED);
  const [deleteInsured] = useMutation(DELETE_INSURED);
  const [addBeneficiaries] = useMutation(ADD_BENEFICIARY, {
    refetchQueries: [
      {
        query: GET_POLICY,
        variables: {
          policyId,
        },
      },
    ],
  });
  const [updateBeneficiaries] = useMutation(UPDATE_BENEFICIARY, {
    refetchQueries: [
      {
        query: GET_POLICY,
        variables: {
          policyId,
        },
      },
    ],
  });
  const [deleteBeneficiaries] = useMutation(DELETE_BENEFICIARY, {
    refetchQueries: [
      {
        query: GET_POLICY,
        variables: {
          policyId,
        },
      },
    ],
  });
  const [addAssetManager] = useMutation(ADD_ASSET_MANAGER);
  const [updateAssetManager] = useMutation(UPDATE_ASSET_MANAGER);
  const [deleteAssetManager] = useMutation(DELETE_ASSET_MANAGER);

  const onSubmit = async submitValues => {
    const formValues = {};
    Object.keys(submitValues).forEach(key => {
      if (key !== 'insured' && key !== 'asset_managers') {
        formValues[key] = submitValues[key];
      }
    });

    // update policy only if data has changed (to clean audit log)
    let updateP = false;
    Object.keys(formValues).forEach(key => {
      if (data.policy[key] !== formValues[key]) {
        updateP = true;
      }
    });

    if (updateP) {
      await updatePolicy({
        variables: {
          policyId,
          formValues,
        },
      }).catch(e => {
        console.error(e);
        throw new Error(parseApolloError(e));
      });
    }

    let promises = [];
    // insured mutations
    if (submitValues.insured) {
      promises = submitValues.insured.map((item, i) => {
        const insuredValues = {
          name: item.name,
          policy_id: policyId,
        };

        if (item.id && !item.name) {
          return deleteInsured({
            variables: {
              insuredId: item.id,
            },
          });
        }

        if (item.id && data.policy.insured[i].name !== item.name) {
          return updateInsured({
            variables: {
              insuredId: item.id,
              insuredValues,
            },
          });
        }

        if (!item.id && item.name) {
          return addInsured({
            variables: {
              insuredValues,
            },
          });
        }
        return Promise.resolve();
      });
    }

    if (submitValues.asset_managers) {
      // asset manager mutations
      promises = submitValues.asset_managers.map((item, i) => {
        const assetManagerValues = {
          name: item.name,
          firm: item.firm,
          policy_id: policyId,
        };

        if (item.id && !item.name) {
          return deleteAssetManager({
            variables: {
              assetManagerId: item.id,
            },
          });
        }

        if (
          item.id &&
          (data.policy.asset_managers[i].name !== item.name ||
            data.policy.asset_managers[i].firm !== item.firm)
        ) {
          return updateAssetManager({
            variables: {
              assetManagerId: item.id,
              assetManagerValues,
            },
          });
        }

        if (!item.id && item.name) {
          return addAssetManager({
            variables: {
              assetManagerValues,
            },
          });
        }
        return Promise.resolve();
      });
    }

    // Update all sub-objects
    await Promise.all(promises);

    // then update the policy, which will issue a refetch
    return updatePolicy({
      variables: {
        policyId,
        formValues,
      },
    }).catch(e => {
      console.error(e);
      throw new Error(parseApolloError(e));
    });
  };

  const addBeneficiary = async formValues => {
    formValues.policy_id = policyId;

    return addBeneficiaries({
      variables: {
        formValues,
      },
    }).catch(e => {
      console.error(e);
      throw new Error(parseApolloError(e));
    });
  };

  const updateBeneficiary = async (id, formValues) =>
    updateBeneficiaries({
      variables: {
        beneficiaryId: id,
        formValues,
      },
    });

  const deleteBeneficiary = id =>
    deleteBeneficiaries({
      variables: {
        beneficiaryId: id,
      },
    });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const policyType = typeOptions.filter(
    option => option.value === data.policy.type,
  );

  const hideButtons = ['edit', 'save', 'delete', 'cancel'];

  return (
    <>
      <Box marginLeft={{ base: '3rem', xl: '7rem' }}>
        <H2 as="h1" marginTop="7rem" marginBottom="1.5rem">
          Policy Info
        </H2>
        <Text marginBottom="10rem" color="nude" fontSize="xl">
          {policyType[0] && `${policyType[0].label} |`}{' '}
          {data.policy.provider && data.policy.provider}{' '}
          {data.policy.number && ` | ${data.policy.number}`}
        </Text>
        {/* <Text marginBottom="0.5rem" color="nude" fontSize="xl">
          Deposit | Withdrawal | Report claim
        </Text> */}
      </Box>
      {['client'].includes(user.claims.role) && (
        <>
          <BasicInformation
            policy={data.policy}
            typeOptions={typeOptions}
            onSubmit={onSubmit}
            hideButtons={hideButtons}
          />
          <Beneficiaries
            beneficiaryData={data.policy.beneficiaries}
            updateBeneficiary={updateBeneficiary}
            addBeneficiary={beneficiaryData =>
              addBeneficiary(beneficiaryData, {})
            }
            deleteBeneficiary={deleteBeneficiary}
            hideButtons={hideButtons}
          />
          <DatesAndCoverage
            policy={data.policy}
            onSubmit={onSubmit}
            hideButtons={hideButtons}
          />
          <PaymentInformation
            policy={data.policy}
            onSubmit={onSubmit}
            hideButtons={hideButtons}
          />
        </>
      )}
      {['manager', 'sales'].includes(user.claims.role) && (
        <>
          <Flex flexDirection={{ base: 'column', xxl: 'row' }}>
            <Box
              width={{ base: '100%', xxl: user.claims.role === 'sales' ? '100%' : '50%' }}
              order={{ base: '1', xxl: '0' }}
            >
              <BasicInformation
                policy={data.policy}
                typeOptions={typeOptions}
                onSubmit={onSubmit}
              />
            </Box>
            {['manager'].includes(user.claims.role) && (
              <PolicyAuditLog policyId={policyId} />
            )}
          </Flex>
          <Beneficiaries
            beneficiaryData={data.policy.beneficiaries}
            updateBeneficiary={updateBeneficiary}
            addBeneficiary={beneficiaryData =>
              addBeneficiary(beneficiaryData, {})
            }
            deleteBeneficiary={deleteBeneficiary}
          />
          <DatesAndCoverage policy={data.policy} onSubmit={onSubmit} />
          <PaymentInformation policy={data.policy} onSubmit={onSubmit} />
          <Pricing policy={data.policy} onSubmit={onSubmit} />
        </>
      )}
    </>
  );
}

Policy.propTypes = {
  match: shape({
    url: string,
  }).isRequired,
};

export default Policy;
