import React, { useState, useEffect } from 'react';
import { arrayOf, shape, bool } from 'prop-types';
import { Button } from '@chakra-ui/core';
import FormField from '../../Form/FormField';
import FormFieldRow from '../../Form/FormFieldRow'

function AssetManager({assetManagerData, editable}) {
  const [assetManagerCount, setAssetManagerCount] = useState(assetManagerData.length === 0 ? assetManagerData.push({}) : assetManagerData.length);

  useEffect(() => {
    setAssetManagerCount(assetManagerData.length);
  }, [assetManagerData]); // eslint-disable-line

  const addNewAssetManager = () => {
    setAssetManagerCount(prev => prev + 1);
  };

  return (
    <>
      {Array.from({ length: assetManagerCount }, (_, i) => i).map(i => (
        <>
          <FormField
            label={`Asset manager ${i + 1}`}
            editable={editable}
            fields={[
              {
                name: `asset_managers[${i}].name`,
                placeholder: 'Asset manager name',
              },
              {
                name: `asset_managers[${i}].id`,
                type: 'hidden',
                value: assetManagerData[i] && assetManagerData[i].id
              }
            ]}
          />
          <FormField
            label={`Asset manager firm ${i + 1}`}
            editable={editable}
            fields={[
              {
                name: `asset_managers[${i}].firm`,
                placeholder: 'Asset manager’s company name',
              },
            ]}
          />
        </>
      ))}
      {editable && (
        <FormFieldRow borderTop="0" label="" lookEditable={editable}>
          <Button
            mb="1rem"
            rounded="0"
            bg="grey"
            type="button"
            maxWidth="110px"
            onClick={addNewAssetManager}
            fontWeight="bold"
            variant="ghost"
            alignSelf="flex-end"
          >
            Add +
          </Button>
        </FormFieldRow>
      )}
    </>
  );
}

AssetManager.propTypes = {
  assetManagerData: arrayOf(shape({})),
  editable: bool.isRequired,
};

AssetManager.defaultProps = {
  assetManagerData: [{}],
};

export default AssetManager;
