import React from 'react';
import { bool, node } from 'prop-types';
import { PseudoBox } from '@chakra-ui/core';

function Container({ children, seamless, ...rest }) {
  return (
    <PseudoBox
      flexGrow="1"
      margin="0"
      position="relative"
      w="auto"
      maxW="8xl"
      padding={{
        base: `${seamless ? '0' : '40px 0'}`,
        sm: '0 20px 0 110px',
        md: '0 130px 0 110px',
      }}
      {...rest}
    >
      {children}
    </PseudoBox>
  );
}

Container.propTypes = {
  children: node.isRequired,
  seamless: bool,
};

Container.defaultProps = {
  seamless: false,
};

export default Container;
