import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';

const propTypes = {
  children: node.isRequired,
};

const StyledButton = styled.button`
  appearance: none;
  outline: 0;
  display: block;
  padding: 0.5em 1em 0.5em 0;
  font-weight: 700;
`;

const FormButton = ({ children, ...props }) => (
  <StyledButton variant="link" type="button" {...props}>
    {children}
  </StyledButton>
);

FormButton.propTypes = propTypes;

export default FormButton;
