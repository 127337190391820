import React from 'react';
import { shape, bool } from 'prop-types';
import { Flex, Text, Box, Image, Link } from '@chakra-ui/core';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loading from '../../Loading';
import Error from '../../Error';
import { useAuth0User } from '../../../hooks/Auth0';

const GET_PERSON = gql`
  query getPersons($personId: Int!) {
    persons_by_pk(id: $personId) {
      id
      first_name
      last_name
    }
  }
`;

function AuditLogRow({ log, darkTheme }) {
  const personId = log.row_data.person_id ? log.row_data.person_id : 0;
  const userId = log.hasura_user['x-hasura-user-id'];

  const { data: personData, loading, error } = useQuery(GET_PERSON, {
    variables: { personId },
  });

  const userData = useAuth0User(userId);

  const renderDate = dateString => {
    const date = new Date(dateString);
    return moment(date, 'YYYYMMDD').fromNow();
  };

  const printString = string => {
    const output = string.replace(/_/g, ' ');
    return output.charAt(0).toUpperCase() + output.substring(1);
  };

  const renderLogMessage = (log, person) => {
    let logMessage;
    const name = person && (
      <Link href={`/clients/${person.id}`}>
        {person.first_name} {person.last_name}
      </Link>
    );

    if (log.table_name === 'person_notes') {
      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added note "{log.row_data.content}" to {name}
          </Text>
        );
      } else if (log.action === 'U') {
        logMessage = (
          <Text display="inline-block">
            changed note "{log.changed_fields.content}" from {name}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted note "{log.row_data.content}" from {name}
          </Text>
        );
      }
    }

    if (log.table_name === 'person_wealth') {
      const dataType = printString(log.row_data.type);
      const dataField = printString(log.row_data.name);

      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added {name} Wealth info {dataType}: {dataField} to{' '}
            {log.row_data.amount} {log.row_data.currency}
          </Text>
        );
      } else if (log.action === 'U') {
        logMessage = (
          <Text display="inline-block">
            changed {name} Wealth info {dataType}: {dataField} from{' '}
            {log.row_data.amount} {log.row_data.currency}{' '}
            {log.changed_fields.amount &&
              `to ${log.changed_fields.amount} ${log.row_data.currency}`}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted {name} Wealth info {dataType}: {dataField}
          </Text>
        );
      }
    }

    if (log.table_name === 'persons') {
      let personType;
      personType = log.row_data.spouse_id ? 'spouse' : 'person';
      personType = log.row_data.parent_id ? 'child' : 'person';
      const personName = (
        <Link href={`/clients/${log.row_data.id}`}>
          {log.row_data.first_name} {log.row_data.last_name}
        </Link>
      );

      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added new {personType} {personName}
          </Text>
        );
      } else if (log.action === 'U') {
        let changedFields = '';
        if (log.changed_fields) {
          changedFields = Object.keys(log.changed_fields).map((key, i) => {
            return Object.keys(log.changed_fields).length === i + 1
              ? ` ${printString(key)}`
              : ` ${printString(key)},`;
          });
        }
        logMessage = (
          <Text display="inline-block">
            changed {personType} {personName} Personal info: {changedFields}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted {personType} {personName}
          </Text>
        );
      }
    }

    if (log.table_name === 'asset_managers') {
      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added a new Asset manager {log.row_data.name}
          </Text>
        );
      } else if (log.action === 'U') {
        let changedFields = '';
        if (log.changed_fields) {
          changedFields = Object.keys(log.changed_fields).map((key) => {
            return ` ${printString(key)}`;
          });
        }
        logMessage = (
          <Text display="inline-block">
            changed Asset manager {log.row_data.name} {changedFields.length !== 0 && `fields: ${changedFields}`}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted Asset manager {log.row_data.name}
          </Text>
        );
      }
    }

    if (log.table_name === 'insured') {
      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added a new Insured {log.row_data.name}
          </Text>
        );
      } else if (log.action === 'U') {
        let changedFields = '';
        if (log.changed_fields) {
          changedFields = Object.keys(log.changed_fields).map((key) => {
            return ` ${printString(key)}`;
          });
        }
        logMessage = (
          <Text display="inline-block">
            changed Insured {log.row_data.name} {changedFields.length !== 0 && `fields: ${changedFields}`}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted Insured {log.row_data.name}
          </Text>
        );
      }
    }

    if (log.table_name === 'beneficiaries') {
      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added a new Beneficiary {log.row_data.name}
          </Text>
        );
      } else if (log.action === 'U') {
        let changedFields = '';
        if (log.changed_fields) {
          changedFields = Object.keys(log.changed_fields).map((key) => {
            return ` ${printString(key)}`;
          });
        }
        logMessage = (
          <Text display="inline-block">
            changed Beneficiary {log.row_data.name} {changedFields.length !== 0 && `fields: ${changedFields}`}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted Beneficiary {log.row_data.name}
          </Text>
        );
      }
    }

    if (log.table_name === 'person_policies') {
      const policyNumberLink = (
        <Link href={`/clients/${log.row_data.person_id}/policies/${log.row_data.id}`}>
          {log.row_data.number}
        </Link>
      );

      if (log.action === 'I') {
        logMessage = (
          <Text display="inline-block">
            added a new Policy {policyNumberLink}
          </Text>
        );
      } else if (log.action === 'U') {
        let changedFields = '';
        if (log.changed_fields) {
          changedFields = Object.keys(log.changed_fields).map((key) => {
            let result = '';
            if (key === 'risk_cover_amount' && log.changed_fields.risk_cover_amount && log.row_data.risk_cover_amount) {
              if (log.changed_fields.risk_cover_amount < log.row_data.risk_cover_amount) {
                const amount = parseInt(log.row_data.risk_cover_amount, 10) - parseInt(log.changed_fields.risk_cover_amount, 10);
                result = ` (New withdrawal of ${amount} ${log.row_data.risk_cover_currency})`;
              } else {
                const amount = parseInt(log.changed_fields.risk_cover_amount, 10) - parseInt(log.row_data.risk_cover_amount, 10);
                result = ` (New deposit of ${amount} ${log.row_data.risk_cover_currency})`;
              }
            }
            return ` ${printString(key)}${printString(result)}`;
          });
        }
        logMessage = (
          <Text display="inline-block">
            changed Policy {policyNumberLink} {changedFields.length !== 0 && `fields: ${changedFields}`}
          </Text>
        );
      } else if (log.action === 'D') {
        logMessage = (
          <Text display="inline-block">
            deleted Policy {policyNumberLink}
          </Text>
        );
      }
    }

    return logMessage;
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error message="There was a problem loading activity message" />;
  }

  return (
    <>
      <Flex
        p={darkTheme ? "1rem 0 0" : "1rem"}
        color="space"
        flexDirection={{ base: 'column', lg: 'row' }}
        borderBottom={darkTheme ? "1px solid" : "0"}
        borderColor={darkTheme ? "gray" : "light-grey"}
      >
        { !darkTheme && (
          <Flex
            flexBasis="5%"
            minW="80px"
            marginBottom={{ base: '1rem', lg: '0' }}
          >
            <Image
              bg="light-grey"
              borderRadius="3rem"
              width="60px"
              height="60px"
              src={userData ? userData.picture : ''}
            />
          </Flex>
        )}
        <Flex
          borderBottom={darkTheme ? "0" : "1px solid"}
          borderColor={darkTheme ? "gray" : "light-grey"}
          flexBasis="85%"
          flexDirection="column"
          marginBottom={{ base: '1rem', lg: '0' }}
          paddingBottom={darkTheme ? '1.25rem' : '2rem'}
        >
          <Box marginBottom="5px" color={darkTheme ? "white" : "space"}>
            <Text display="inline-block" fontWeight={darkTheme ? "normal" : "bold"} marginRight="3px">
              {userData && userData.name}
            </Text>
            {renderLogMessage(log, personData.persons_by_pk)}
          </Box>
          <Text color={darkTheme ? "gray" : "info"} lineHeight="1.4">
            {renderDate(log.action_tstamp_tx)}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}

AuditLogRow.propTypes = {
  log: shape({}),
  darkTheme: bool
};

AuditLogRow.defaultProps = {
  darkTheme: false,
};

export default AuditLogRow;
