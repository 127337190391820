import React from 'react';
import { string, shape, func } from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@chakra-ui/core';
import FormBox from '../../Form/FormBox';
import FormControls from '../../Form/FormControls';
import { useAuth0User, DELETE_AUTH0_USER } from '../../../hooks/Auth0';
import { parseApolloError } from '../../../util/apollo';
import { Redirect } from '../../../util/router';

function Credentials({ person, removePerson }) {
  const auth0User = useAuth0User(person.auth0_sub);

  const [deleteAuth0User] = useMutation(DELETE_AUTH0_USER);

  const onDeleteClient = async () => {
    if (
      // eslint-disable-next-line no-alert
      !window.confirm(
        `Are you sure you want to delete all client records for
${person.first_name} ${person.last_name}?

If you want to preserve the client data, but prevent
${person.first_name} ${person.last_name} from logging in,
please revoke the login credentials instead.`,
      )
    ) {
      return Promise.reject(new Error());
    }

    if (auth0User) {
      await deleteAuth0User({
        variables: { userSub: auth0User.user_id },
      }).catch(e => {
        console.error(e);
        throw new Error(parseApolloError(e));
      });
    }

    return removePerson({
      variables: {
        personId: person.id,
      },
    });
  };

  return (
    <FormBox heading="Terminate account">
      <FormControls onSubmit={onDeleteClient} hideButtons={['edit']}>
        {({ submitForm, submitting, submitted }) =>
          submitted ? (
            <Redirect to="/clients" />
          ) : (
            <Button
              bg="danger"
              color="white"
              type="button"
              marginBottom="1rem"
              maxWidth="180px"
              onClick={submitForm}
              isLoading={submitting}
              loadingText="Deleting"
            >
              Delete account
            </Button>
          )
        }
      </FormControls>
    </FormBox>
  );
}

Credentials.propTypes = {
  person: shape({
    sub: string,
  }).isRequired,
  removePerson: func.isRequired,
};

export default Credentials;
