import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { string } from 'prop-types';
import gql from 'graphql-tag';
import { Box, Flex, Link } from '@chakra-ui/core';
import { H3 } from '../../Typography';
import AuditLogRow from '../AuditLogRow';
import Loading from '../../Loading';
import Error from '../../Error';


const AUDIT_LOG_FIELDS = gql`
  fragment auditLogFields on audit_logged_actions {
    action
    table_name
    row_data
    changed_fields
    hasura_user
    action_tstamp_tx
  }
`;

const GET_AUDIT_LOG = gql`
  query getAuditLog($jsonFilter: jsonb, $jsonIdFilter: jsonb) {
    audit_logged_actions(
      where: {
        _or: [
          {
            _and: {
              table_name: {_in: ["person_policies"]},
              row_data: {_contains: $jsonIdFilter}
            }
          },
          {
            _and: {
              table_name: {_in: ["beneficiaries", "asset_managers", "insured"]},
              row_data: {_contains: $jsonFilter}
            }
          },
        ]
      },
      order_by: {action_tstamp_tx: desc},
      limit: 5) {
        ...auditLogFields
      }
  }
  ${AUDIT_LOG_FIELDS}
`;


function PolicyAuditLog({ policyId }) {
  const jsonFilter = {
    "policy_id": parseInt(policyId, 10)
  }
  const jsonIdFilter = {
    "id": parseInt(policyId, 10)
  }

  const { error, loading, data } = useQuery(GET_AUDIT_LOG, {
    variables: { jsonFilter, jsonIdFilter } 
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <Box bg="space" m={{base: "-5rem 0 1.25rem", xxl: "-5rem 0 1.25rem 1.25rem"}} width={{ base: "100%", xxl:"50%" }} p="80px 40px 50px">
      <H3 as="h2" color="white" marginBottom={{ base: '2rem', lg: '2rem' }}>Activity</H3>
        {data && data.audit_logged_actions.length !== 0 ? (
            data.audit_logged_actions.map(
              (log, i) => <AuditLogRow key={i} log={log} darkTheme />,
            )
        ) : (
          <Box>There no new activity</Box>
        )}
        {data.audit_logged_actions.length !== 0 && (
          <Flex justifyContent="center" p="3rem 0 0">
            <Link href="/activity" fontWeight="bold" color="sky">View all actions</Link>
          </Flex>
        )}
    </Box>
  );
}

PolicyAuditLog.propTypes = {
  policyId: string.isRequired,
};

export default PolicyAuditLog;