import React, { useEffect } from 'react';
import { string, number, bool } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

function MonetaryInput({ asText, ...props }) {
  const { register, setValue } = useFormContext();
  const { name, initialValue } = props;

  // console.log('MonetaryInput value for ', name, 'is', initialValue);

  useEffect(() => {
    if (name !== 'field.name') {
      register({ name });
    }
  }, [register, name]);

  return (
    <NumberFormat
      onValueChange={({ floatValue }) => floatValue ? setValue(name, floatValue) : setValue(name, null)}
      type="text"
      displayType={asText ? 'text' : 'input'}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale="2"
      allowNegative={false}
      defaultValue={initialValue === undefined ? null : initialValue}
      // eslint-disable-next-line
      {...props}
    />
  );
}

MonetaryInput.propTypes = {
  name: string.isRequired,
  initialValue: number,
  asText: bool,
};
MonetaryInput.defaultProps = {
  asText: false,
};

export default MonetaryInput;
