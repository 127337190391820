import React from 'react';
import { shape } from 'prop-types';
import AuditLog from '../../components/AuditLog';
import { useAuth } from '../../util/auth';
import { Route } from '../../util/router';
import Loading from '../../components/Loading';

function ActivityPage({ match }) {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return <Route exact path={`${match.url}/`} component={AuditLog} />;
}

ActivityPage.propTypes = {
  match: shape({}).isRequired,
};

export default ActivityPage;
