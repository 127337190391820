import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  PseudoBox,
  Box,
  Input,
  Textarea,
  Text,
} from '@chakra-ui/core';
import moment from 'moment';

import MonetaryInput from '../MonetaryInput';
import SelectField from '../SelectField';
import FormFieldRow from '../FormFieldRow';

const propTypeField = shape({
  type: string,
  name: string,
  placeholder: string,
  validations: shape({}),
});

const propTypes = {
  fields: arrayOf(propTypeField).isRequired,
  editable: bool,
  lookEditable: bool,
  label: string,
  customValue: func,
};

const defaultProps = {
  editable: false,
  lookEditable: false,
  label: '',
};

function FormField({
  editable,
  lookEditable,
  label,
  fields,
  customValue: CustomValueComponent,
}) {
  const { errors, watch, register } = useFormContext();
  const values = watch(fields.map(f => f.name));

  return (
    <FormFieldRow
      lookEditable={editable || lookEditable}
      name={fields[0].name}
      label={label}
    >
      {fields.map(
        ({
          type = 'text',
          name,
          key,
          placeholder,
          value,
          options,
          validations = {},
        }) => {
          if (type === 'hidden') {
            return (
              <Input
                key={key || name}
                type="hidden"
                name={name}
                ref={register}
                value={value && value !== 'undefined' ? value : ''}
              />
            );
          }
          if (type === 'monetary') {
            return (
              <PseudoBox d={editable ? '' : 'none'} key={key || name}>
                <MonetaryInput
                  className={`${errors[name] ? 'is-error' : ''}`}
                  id={name}
                  name={name}
                  initialValue={values[name]}
                  customInput={Input}
                  placeholder={placeholder}
                  marginBottom="1rem"
                  borderColor="transparent"
                  rounded="0"
                />
              </PseudoBox>
            );
          }
          if (type === 'select') {
            return (
              <PseudoBox d={editable ? '' : 'none'} key={key || name} w="100%">
                <SelectField
                  className={`${
                    errors[name] ? 'select-field is-error' : 'select-field'
                  }`}
                  placeholder={placeholder}
                  name={name}
                  options={options && options}
                  initialValue={values[name]}
                />
              </PseudoBox>
            );
          }
          if (type === 'textarea') {
            return (
              <Textarea
                d={editable ? '' : 'none'}
                key={key || name}
                className={`${errors[name] ? 'is-error' : ''}`}
                placeholder={placeholder}
                id={name}
                name={name}
                type={type}
                ref={register(validations)}
                isInvalid={!!errors[name]}
                borderColor="transparent"
                rounded="0"
                marginBottom="1rem"
                _invalid={{
                  border: '1px solid',
                  borderColor: 'danger',
                }}
              />
            );
          }

          return (
            <>
              <Input
                d={editable ? '' : 'none'}
                key={key || name}
                className={`${errors[name] ? 'is-error' : ''}`}
                placeholder={placeholder}
                id={name}
                name={name}
                type={type === 'date' ? 'text' : type}
                ref={register(validations)}
                isInvalid={!!errors[name]}
                borderColor="transparent"
                rounded="0"
                marginBottom="1rem"
                _invalid={{
                  border: '1px solid',
                  borderColor: 'danger',
                }}
              />
              {errors[name] && validations.message && (
                <Text color="danger" pb="1rem">
                  {validations.message}
                </Text>
              )}
            </>
          );
        },
      )}

      <Box
        as="p"
        p={{ base: '0', md: '0 1rem' }}
        marginBottom="1rem"
        d={editable ? 'none' : ''}
      >
        <DisplayValue
          field={fields[0]}
          values={values}
          customValue={CustomValueComponent}
        />
      </Box>
    </FormFieldRow>
  );
}

function DisplayValue({ customValue: CustomValueComponent, field, values }) {
  if (CustomValueComponent) {
    return <CustomValueComponent {...values} />;
  }

  if (field.type === 'monetary') {
    return (
      <MonetaryInput
        asText
        name="field.name"
        value={values[field.name] === undefined ? null : values[field.name]}
      />
    );
  }

  if (field.type === 'date') {
    if (values[field.name]) {
      const date = moment(values[field.name], 'DD-MM-YYYY');
      return date.format('DD-MM-YYYY');
    }
  }

  if (field.type === 'select' && field.options) {
    const fieldValue = field.options.find(option => {
      return (
        option.value === values.accountable_id ||
        option.value === values.backup_person_id ||
        option.value === values.type ||
        option.value === values.status ||
        option.value === values.relationship
      );
    });
    return fieldValue ? fieldValue.label : '';
  }

  if (typeof values[field.name] !== 'undefined') {
    return values[field.name];
  }

  return null;
}

DisplayValue.propTypes = {
  field: propTypeField.isRequired,
  values: shape({}).isRequired,
  customValue: func,
};

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
